import {isEmpty} from "lodash";

export default function () {
  const ruleName = "required";
  const validation = (val) => {

    if(val === null || val === undefined)
      return false;
    else if(typeof(val) === "object")
      return !isEmpty(val);
    else
      val = val.toString();
    return !(val.length === 0 || !val.trim());
  }
  return {
    ruleName: ruleName,
    validationFunc: validation
  };
}
