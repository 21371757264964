export function fileDownload(responseData, responseHeaders){
  const url = window.URL.createObjectURL(new Blob([responseData]));
  const fileName = responseHeaders["content-disposition"].split("filename=")[1].split(";")[0].replaceAll('"', '');
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  window.URL.revokeObjectURL(url);
}

export default fileDownload
