import { decode } from 'js-base64';

export default function (token, section = 1){
  try {
    return JSON.parse(decode(token.split('.')[section]));
  } catch (e) {
    try{
      return JSON.parse(decodeURIComponent(escape(window.atob(token.split('.')[section]))));
    }
    catch(e){
      console.error("token_solver içerisinde hata oluştu.")
      return undefined;
    }
  }
}
