import {$fns} from "src/infrastructure/functions/functions";
import {ref} from 'vue';
const withPrefix = (prefix, routes) =>
  routes.map( (route) => {
    route.path = prefix + route.path;
    return route;
  });

const routes = [
  {
    path: '/login',
    component: () => import('pages/Public/Login/Login.vue'),
    name: 'login',
    props: route => ({ query: {
      'tedarikci_personel_kayit_id': route.query.tedarikci_personel_kayit_id,
      'kiraci_tedarikci_personel_kayit_id': route.query.kiraci_tedarikci_personel_kayit_id,
    }}),
  },
  {
    path: '/sifremi-unuttum',
    component:()=>import('pages/Public/Login/SifremiUnuttum'),
    name:'sifremiUnuttum'
  },
  {
    path: '/qr1',
    component: () => import('pages/_oldFiles/QrDeneme1.vue'),
    name: 'qr1'
  },
  {
    path: '/sifre_yenile',
    component: () => import('pages/Public/Login/SifremiYenile.vue'),
    name: 'sifreYenile',
    props: route=> ({query:route.query.id})
  },
  {
    path: '/sifre_yenileme_bilgisi',
    component: () => import('pages/Public/Login/BeforeSifremiYenile.vue'),
    name: 'beforeSifreYenile'
  },
  {
    path: '/signup',
    component: () => import('pages/Public/SignupRelated/SignUp.vue'),
    name: 'signup'
  },
  {
    path: '/mail_onayla',
    component: () => import('pages/Public/SignupRelated/MailOnay.vue'),
    name: 'mailOnay',
    props: route=> ({query:route.query.id})
  },
  {
    path: '/mail_onayi_gerekiyor',
    component: () => import('pages/Public/SignupRelated/BeforeMailOnay.vue'),
    name: 'beforeMailOnay'
  },
  {
    path: '/EppsoMallLogin',
    component: () => import('pages/Public/Login/JwtLogin.vue'),
    name: 'JwtLogin'
  },
  {
    path: '/company_enrollment',
    component: () => import('pages/Public/CompanyRelated/CompanyEnrollment.vue'),
    name: 'companyEnrollment',
    props: route => ({ query: route.query.enrollment_id }),
    //props: (route) => ({ companyEnrollmentId: route.query.enrollment_id })
  },
  {
    path: '/personel_kayit',
    component: () => import('pages/Public/PropertyRelated/PersonelKaydi'),
    name: 'personel_kayit',
    //props: route => ({ query: route.query.enrollment_id }),
  },


  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/kalendardeneme',
        component: () => import('pages/KalendarDenemePage.vue'),
        name: 'kalendardeneme',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },


      {
        path: '',
        component: () => import('pages/EppsoRelated/EPPSO_Dashboard'),
        name: 'index',
        props: route => ({ query: route.query.enrollment_id }),
        meta: { requiresAuth: true, hasAtLeastOneOf: [],
          middleware(from, to, next){
            console.log('hello')
            console.log(from.query.hasOwnProperty('kiraci_tedarikci_personel_kayit_id'))
            if(from.query.hasOwnProperty('enrollment_id') && from.name !== 'YonetimPersoneliEklemeSureciPersonelOnayi')
              next({
                name: 'YonetimPersoneliEklemeSureciPersonelOnayi',
                params: {'enrollment_id': from.query['enrollment_id']},
                query: {'enrollment_id': from.query['enrollment_id']}
              })
            else if(from.query.hasOwnProperty('tedarikci_personel_kayit_id') && from.name !== 'TedarikciPersoneliEklemeSureciPersonelOnayi')
              next({
                name: 'TedarikciPersoneliEklemeSureciPersonelOnayi',
                params: {'tedarikci_personel_kayit_id': from.query['tedarikci_personel_kayit_id']},
                query: {'tedarikci_personel_kayit_id': from.query['tedarikci_personel_kayit_id']}
              })
            else if(from.query.hasOwnProperty('kiraci_personel_kayit_id') && from.name !== 'KiraciPersoneliEklemeSureciPersonelOnayi')
              next({
                name: 'KiraciPersoneliEklemeSureciPersonelOnayi',
                params: {'kiraci_personel_kayit_id': from.query['kiraci_personel_kayit_id']},
                query: {'kiraci_personel_kayit_id': from.query['kiraci_personel_kayit_id']}
              })
            else if(from.query.hasOwnProperty('kiraci_tedarikci_personel_kayit_id') && from.name !== 'KiraciTedarikciPersoneliEklemeSureciPersonelOnayi')
              next({
                name: 'KiraciTedarikciPersoneliEklemeSureciPersonelOnayi',
                params: {'kiraci_tedarikci_personel_kayit_id': from.query['kiraci_tedarikci_personel_kayit_id']},
                query: {'kiraci_tedarikci_personel_kayit_id': from.query['kiraci_tedarikci_personel_kayit_id']}
              })
          }
        }
      },

      // {
      //   path: 'egitim-videolari',
      //   component: () => import('src/pages/EppsoRelated/EgitimVideolari') ,
      //   name: 'EgitimVideolari',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },
      //Egitim Videolari
      ...withPrefix('egitim-videolari', [
        {
          path: '',
          component: () => import('src/pages/EppsoRelated/EgitimVideolari/EgitimVideolari_Dashboard') ,
          name: 'EgitimVideolari_Dashboard',
          meta: { requiresAuth: true, hasAtLeastOneOf: [] },
          title : "Eğitim Videoları",
          fieldName : 'egitimVideolari'
        },

        {
          path: '/admin',
          component: () => import('src/pages/EppsoRelated/EgitimVideolari/EgitimVideolari_EppsoAdmin') ,
          name: 'EgitimVideolari_EppsoAdmin',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
        },

        {
          path: '/yonetim',
          component: () => import('src/pages/EppsoRelated/EgitimVideolari/EgitimVideolari_Yonetim') ,
          name: 'EgitimVideolari_Yonetim',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [] }
        },

        {
          path: '/kiraci',
          component: () => import('src/pages/EppsoRelated/EgitimVideolari/EgitimVideolari_Kiraci') ,
          name: 'EgitimVideolari_Kiraci',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [] }
        },

        {
          path: '/tedarikci',
          component: () => import('src/pages/EppsoRelated/EgitimVideolari/EgitimVideolari_Tedarikci') ,
          name: 'EgitimVideolari_Tedarikci',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [] }
        },

      ]),


      // {
      //   path: 'egitim-videolari',
      //   component: () => import('src/pages/EppsoRelated/EgitimVideolariYeni') ,
      //   name: 'EgitimVideolariYeni',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },

      // TODO: \\_WILL_BE_MOVED_// Yönetim Personeli Ekleme Süreci - Personel Onayi
      {
        path: '/avm_personel_enrollment',
        name: 'YonetimPersoneliEklemeSureciPersonelOnayi',
        props: route => ({ query: route.query.enrollment_id }),
        component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelEklemeSurecleri/personelDavetKabul'),
        meta: { requiresAuth: true },
      },

      {
        path: '/tedarikci-personel-kayit',
        name: 'TedarikciPersoneliEklemeSureciPersonelOnayi',
        props: route => ({ query: route.query.tedarikci_personel_kayit_id }),
        component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelEklemeSurecleri/personelDavetKabul'),
        meta: { requiresAuth: true },
      },
      {
        path: '/kiraci-tedarikci-personel-kayit',
        name: 'KiraciTedarikciPersoneliEklemeSureciPersonelOnayi',
        props: route => ({ query: route.query.kiraci_tedarikci_personel_kayit_id }),//used to be kiraci_personel_kayit_id
        component: () => import('pages/PropertyRelated/Magazam/KiraciTedarikciPersonel/PersonelEklemeSurecleri/personelDavetKabul'),
        meta: { requiresAuth: true },
      },
      {
        path: '/kiraci-personel-kayit',
        name: 'KiraciPersoneliEklemeSureciPersonelOnayi',
        props: route => ({ query: route.query.kiraci_personel_kayit_id }),
        component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/PersonelEklemeSurecleri/personelDavetKabul'),
        meta: { requiresAuth: true },
      },
      ...withPrefix('/t',[
        //Magazam
        { path: '/magazam',
          component: () => import('pages/PropertyRelated/Magazam/Magazam') ,
          name: 'Magazam',
          title : 'Mağazam Sayfası',
          preventThisPageForSeen: true,
          fieldName : 'MagazamSayfasi',
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Mağazam Detay - Görüntüleme"] }
        },
        { path: '/Personeller',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersoneliSekme'),
          name: 'MagazaPersonel',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Listesi - Görüntüleme"] }
        },
        { path: '/MagazaPersonelArsiv',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersonelArsivListesi'),
          name: 'MagazaPersonelArsiv',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Listesi - Görüntüleme"] }
        },
        // { path: '/Personeller',
        //   component: () => import('pages/PropertyRelated/Magazam/MagazaPersonel'),
        //   name: 'MagazaPersonel',
        //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Listesi - Görüntüleme"] }
        // },

        { path: '/PersonelDavetleri',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersonelDavetleri'),
          name: 'MagazaPersonelDavetleri',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Davet Listesi - Görüntüleme"] }
        },
        { path: '/PersonelDavet',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersonelDavet'),
          name: 'MagazaPersonelDavet',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Davet Listesi - Görüntüleme"] }
        },
        { path: '/PersonelQrOkumalari',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPerseonelGirisCikis'),
          name: 'MagazaPersonelGirisCikis',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Davet Listesi - Görüntüleme"] }
        },
        { path: '/PersonelDavetDetay/:kiraci_personel_kayit_id',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersonelDavetDetay'),
          name: 'MagazaPersonelDavetDetay',
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Davet Listesi - Görüntüleme"] }
        },
        {
          path: '/Personel/:personelID',
          name: 'MagazaPersonelDetay',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersonelDetay'),
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Davet Listesi - Görüntüleme"] },

        },

        { path: '/MagazaPersonel',
          component: () => import('pages/PropertyRelated/Magazam/MagazaPersonelYonetimi_Dashboard'),
          name: 'MagazaPersonelYonetimi_Dashboard_t',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Listesi - Görüntüleme"] }
        },


        //TODO: ilgili claimler eklendiginde meta'lar duzenlenecek

        // { path: '/TedarikciFirmalari',
        //   component: () => import('pages/PropertyRelated/Magazam/KiraciTedarikciFirmasi/KiraciTedarikciFirmasiList'),
        //   name: 'KiraciTedarikciFirmasiList',
        //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Listesi - Görüntüleme"] }
        // },

        //////////////////////////////////////////////////////////////////
        // Firmalar - Subpaths
        ...withPrefix('/TedarikciFirmalari', [

          // Dashboard
          {
            path: '/',
            component: () => import('pages/PropertyRelated/Magazam/Tedarikci Firma Listesi/FirmaListesi_Dashboard'),
            name: 'firmaListesi_t',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                "Geçici Çalışma"]
            }
          },


          // Firma Listesi - Subpaths
          ...withPrefix('/firma-listesi', [

            // Firma Listesi
            {
              path: '/',
              component: () => import ('pages/PropertyRelated/Magazam/Tedarikci Firma Listesi/EPPSOFirmalari/EPPSO_Firmalari'),
              name: 'EPPSO_Firmalari_t',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }
            },

            // Firma Listesi
            {
              path: '/:firmaId',
              component: () => import ('pages/PropertyRelated/Magazam/Tedarikci Firma Listesi/EPPSOFirmalari/AvmFirmaDetay'),
              name: 'AvmFirmaDetay_t',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }
            },

          ]),

          // Marka Ekleme Talepleri - Subpaths
          ...withPrefix('/marka-ekleme-talepleri', [

            // Marka Ekleme Talepleri Listesi
            {
              path: '',
              component: () => import ('pages/PropertyRelated/Magazam/Tedarikci Firma Listesi/MarkaYaratmaTalepleri/MarkaYaratmaTalepleriListesi'),
              name: 'MarkaYaratmaTalepleri_t',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }
            },

          ])

        ]),
        // { path: '/TedarikciPersonel',
        //   component: () => import('pages/PropertyRelated/Magazam/KiraciTedarikciPersonel/KiraciTedarikciPersonelDashboard'),
        //   name: 'KiraciTedarikciPersonel_Dashboard',
        //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Mağazam - Personel Listesi - Görüntüleme"] }
        // },
        { path: '/TedarikciPersonel',
          component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/TedarikciPersonelYonetimi_Dashboard'),
          name: 'TedarikciPersonelYonetimi_Dashboard_t',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }
        },
        // ...withPrefix('/TedarikciPersonel',[
        //   { path: '/personel-listesi',
        //     component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelListesi/PersonelListesi'),
        //     name: 'TedarikciPersoneliListesi_t',
        //     meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }//Mağazam - Personel Listesi - Görüntüleme
        //   },
        ...withPrefix('/TedarikciPersonel',[
          { path: '/personel-listesi',
            component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelListesi/KiraciTedarikciPersoneliSekme'),
            name: 'KiraciTedarikciPersoneliSekme',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }//Mağazam - Personel Listesi - Görüntüleme
          },
          { path: '/personel-arsiv-listesi',
            component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelListesi/KiraciTedarikciPersonelArsivListesi'),
            name: 'KiraciTedarikciPersonelArsiv',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }//Mağazam - Personel Listesi - Görüntüleme
          },
          ...withPrefix('/personel-listesi',[
            // Personel Detay
            {
              path: '/:personelID',
              name: 'TedarikciPersonelDetay_t',
              component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelListesi/Personel'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] },

            },
          ]),


          // Personel Ekleme Süreçleri - Subpaths
          ...withPrefix('/personel-ekleme-surecleri', [

            // Personel Ekleme Süreçleri Listesi
            {
              path: '', //NOTE : Component kullanilmaya devam ediyor ancak bu path artik kullanilmiyor
              name: 'TedarikciPersoneliEklemeSurecleriListesi_t',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelEklemeSurecleri/DavetliPersoneller'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Geçici Çalışma"
                  //"Mağazam - Personel Listesi - Görüntüleme",
                ] },

            },

            // Kiracı Tedarikçi Personeli Ekleme Süreci - Olustur
            {
              path: '/form-olustur',
              name: 'TedarikciPersoneliEklemeSureciOlustur_t',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelEklemeSurecleri/personelDavet'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Geçici Çalışma"
                  //"Mağazam - Personel Listesi - Görüntüleme",
                ] },

            },

            // Kiracı Tedarikçi Personeli Ekleme Süreci - Detay & Yönetim Onay
            {
              path: '/:tedarikci_personel_kayit_id',
              name: 'TedarikciPersoneliEklemeSureciDetay_t',
              component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelEklemeSurecleri/personelDavetDetay'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Geçici Çalışma"
                  //"Mağazam - Personel Listesi - Görüntüleme",
                ] },

            },

          ]),
          // Giriş/Çıkış Listesi
          {
            path: '/giris',
            component: () => import('pages/PropertyRelated/Magazam/TedarikciPersoneli/PersonelGirisCikis/PersonelGirisCikis'),
            name: 'TedarikciPersoneliGirisCikis_t',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Geçici Çalışma"] }
          },
        ]),

        ...withPrefix('/Talepler',[
          { path: '',
            component: () => import('pages/PropertyRelated/Magazam/Talepler/TaleplerDashboard'),
            name: 'Talepler_Dashboard',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                "Geçici Çalışma",
                "İş Emri"
              ] }
          },
          // { path: '/gecici-calisma-talepleri',
          //   component: () => import('pages/PropertyRelated/Magazam/Talepler/GeciciCalisma/GeciciCalismaTalepleri'),
          //   name: 'GeciciCalismaTalepleri',
          //   meta: { requiresAuth: true, hasAtLeastOneOf: [
          //       "Geçici Çalışma"
          //     ] }
          // },
          { path: '/gecici-calisma-talepleri',
            component: () => import('pages/PropertyRelated/Magazam/Talepler/GeciciCalisma/MagazaGeciciCalismaListeleriSekme'),
            name: 'MagazaGeciciCalismaListeleriSekme',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                "Geçici Çalışma"
              ] }
          },
          { path: '/gecici-calisma-talep-olustur',
            component: () => import('pages/PropertyRelated/Magazam/Talepler/GeciciCalisma/GeciciCalismaTalepOlustur'),
            name: 'GeciciCalismaTalepOlustur',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                "Geçici Çalışma"
              ] }
          },
          ...withPrefix('/gecici-calisma-talep-detay',[
            {
              path: '/:TalepId',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/GeciciCalisma/GeciciCalismaTalepDetay') ,
              name: 'GeciciCalismaTalepDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Geçici Çalışma"
                ] }
            },
            {
              path: '/:TalepId',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/GeciciCalisma/GeciciCalismaTalepReadOnlyDetay') ,
              name: 'GeciciCalismaTalepReadOnlyDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Geçici Çalışma"
                ] }
            },
            {
              path: '/revize/:TalepId',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/GeciciCalisma/GeciciCalismaTalepRevizeFormu') ,
              name: 'GeciciCalismaTalepRevizeFormu',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Geçici Çalışma"
                ] }
            },
          ]),
          // Mal Kabul Talebi - Subpaths
          ...withPrefix('/mal-kabul-talepleri', [

            // Mal Kabul Talep Listesi
            {
              path: '',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/MalKabulTalebi/MalKabulTalepListesi'),
              name: 'MalKabulTalepListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [

                  "Mal Kabul",
                  //"Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Görüntüleme",
                ]
              }
            },

            // Mal Kabul - Talep Detay
            {
              path: '/:MalKabulDetayID',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/MalKabulTalebi/MalKabulTalepDetay'),
              name: 'MalKabulTalepDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Mal Kabul",
                  //"Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Görüntüleme",
                ]
              }
            },

            // Mal Kabul - Talep Oluştur
            {
              path: '/mal-kabul-olustur',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/MalKabulTalebi/YeniMalKabulTalebi'),
              name: 'YeniMalKabulTalebi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Mal Kabul",
                  //"Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Ekleme Düzenleme Silme",
                ]
              }
            },

          ]),

          // İş Emri ve Talep Yönetimi - Subpaths
          ...withPrefix('/is-emri-talepleri', [
            {
              path: '',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/Taleplerim/Taleplerim') ,
              name: 'Magazam_Taleplerim',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İş Emri"] }
            },
            {
              path: '/talep-olustur',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/Taleplerim/talepOlustur'),
              name: 'Magazam_TalepOlusturma',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İş Emri"] }
            },
            {
              path: '/TalepDetay/:TalepId',
              component: () => import('pages/PropertyRelated/Magazam/Talepler/Taleplerim/TalepDetay') ,
              name: 'Magazam_TalepDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Mağazam - Talep İşlemleri","İş Emri"
                ] }
            },
          ]),

        ]),
        // { path: '/Talepler',
        //   component: () => import('pages/PropertyRelated/Magazam/Talepler/TaleplerDashboard'),
        //   name: 'Talepler_Dashboard',
        //   meta: { requiresAuth: true, hasAtLeastOneOf: [
        //     //"Mağazam - Personel Listesi - Görüntüleme"
        //     ] }
        // },

        // Ciro Ekleme - Subpaths
        ...withPrefix('/magaza-cirolari', [

          // // Ciro Listesi
          // {
          //   path: '',
          //   component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/KiraciCirolariListesi') ,
          //   name: 'CiroListesi',
          //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
          // },


          // // Ciro Dashboard
          {
            path: '',
            component: () => import('pages/PropertyRelated/Magazam/MagazaCirolari/CiroDashboard.vue') ,
            name: 'MagazaCiroDashboard',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Ciro Ekleme"] }
          },

          // Ciro - Detay
          {
            path: '/ciro-detay',//'/:MagazaID',
            component: () => import('pages/PropertyRelated/Magazam/MagazaCirolari/CiroDetay'),
            name: 'MagazaCiroDetay',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
              // "Kiracı Yönetimi - Ciro Listesi - Görüntüleme"
                "Ciro Ekleme"
              ] }
          },

          {
            path: '/ciro-bilgisi-olustur/:MagazaID',
            component: () => import('pages/PropertyRelated/Magazam/MagazaCirolari/CiroEkleme'),
            name: 'MagazaCiroEklemeWithMagazaID',
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                // "Kiracı Yönetimi - Ciro Listesi - Görüntüleme"
                "Ciro Ekleme"
              ] }
          },
          // Ciro - Detay
          {
            path: '/magaza-ciro-detay',//'/:MagazaID',
            component: () => import('pages/PropertyRelated/Magazam/MagazaCirolari/SozlesmesizCiroDetay'),
            name: 'MagazaSozlesmesizCiroDetay',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                // "Kiracı Yönetimi - Ciro Listesi - Görüntüleme"
                "Ciro Ekleme"
              ] }
          },

          {
            path: '/magaza-ciro-bilgisi-olustur/:MagazaID',
            component: () => import('pages/PropertyRelated/Magazam/MagazaCirolari/SozlesmesizCiroEkleme'),
            name: 'MagazaSozlesmesizCiroEklemeWithMagazaID',
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                // "Kiracı Yönetimi - Ciro Listesi - Görüntüleme"
                "Ciro Ekleme"
              ] }
          },

        ]),
      ]),
      ...withPrefix('/c',[
        //Firmam
        { path: '/firmam',
          component: () => import('pages/FirmaRelated/Firmam/Firmam') ,
          name: 'Firmam',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Firmam - Firmam Detay - Görüntüleme"
            ] }
        },
        { path: '/FirmaPersoneli',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelYonetimi_Dashboard'),
          name: 'FirmaPersonelYonetimi_Dashboard',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
              "Firmam - Firmam Detay - Görüntüleme"
            ] }
        },
        { path: '/FirmaPersoneliArsiv',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelArsivListesi'),
          name: 'FirmaPersonelArsivListesi',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
              "Firmam - Firmam Detay - Görüntüleme"
            ] }
        },
        { path: '/Personeller',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersoneliSekme'),
          name: 'FirmaPersonel',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            //"Firmam - Personel Listesi - Görüntüleme"
            ] }
        },
        // { path: '/Personeller',
        //   component: () => import('pages/FirmaRelated/Firmam/FirmaPersonel'),
        //   name: 'FirmaPersonel',
        //   meta: { requiresAuth: true, hasAtLeastOneOf: [
        //     //"Firmam - Personel Listesi - Görüntüleme"
        //     ] }
        // },
        { path: '/PersonelDavetleri',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelDavetleri'),
          name: 'FirmaPersonelDavetleri',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Firmam - Personel Davet Listesi - Görüntüleme"
            ] }
        },
        { path: '/PersonelDavet',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelDavet'),
          name: 'FirmaPersonelDavet',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Firmam - Personel Davet Listesi - Görüntüleme"
            ] }
        },
        { path: '/PersonelQrOkumalari',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelGirisCikis'),
          name: 'FirmaPersonelGirisCikis',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Firmam - Personel Davet Listesi - Görüntüleme"
            ] }
        },
        { path: '/PersonelDavetDetay/:tedarikci_personel_kayit_id', //(used to be) --> kiraci_personel_kayit_id
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelDavetDetay'),
          name: 'FirmaPersonelDavetDetay',
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Firmam - Personel Davet Listesi - Görüntüleme"
            ] }
        },
        {
          path: '/Personel/:personelID',
          name: 'FirmaPersonelDetay',
          component: () => import('pages/FirmaRelated/Firmam/FirmaPersonelDetay'),
          meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Firmam - Personel Davet Listesi - Görüntüleme"
            ] },

        },


        ...withPrefix('/Talepler',[
          { path: '',
            component: () => import('pages/FirmaRelated/Firmam/Talepler/FirmamTaleplerDashboard'),
            name: 'Firmam_Talepler_Dashboard',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                // "Geçici Çalışma",
                // "İş Emri"
                "Firmam - Talepler - Geçici Çalışma"
              ] }
          },
          { path: '/gecici-calisma-talepleri',
            component: () => import('pages/FirmaRelated/Firmam/Talepler/GeciciCalisma/FirmaGeciciCalismaListeleriSekme'),
            name: 'FirmaGeciciCalismaListeleriSekme',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                //"Geçici Çalışma"
                "Firmam - Talepler - Geçici Çalışma"
              ] }
          },
          { path: '/gecici-calisma-talep-olustur',
            component: () => import('pages/FirmaRelated/Firmam/Talepler/GeciciCalisma/GeciciCalismaTalepOlustur'),
            name: 'GeciciCalismaTalepOlusturForTedarikci',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                //"Geçici Çalışma"
                "Firmam - Talepler - Geçici Çalışma"
              ] }
          },
          ...withPrefix('/gecici-calisma-talep-detay',[
            {
              path: '/:TalepId',
              component: () => import('pages/FirmaRelated/Firmam/Talepler/GeciciCalisma/GeciciCalismaTalepDetay') ,
              name: 'GeciciCalismaTalepDetayForTedarikci',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  //"Geçici Çalışma"
                  "Firmam - Talepler - Geçici Çalışma"
                ] }
            },
            {
              path: '/:TalepId',
              component: () => import('pages/FirmaRelated/Firmam/Talepler/GeciciCalisma/GeciciCalismaTalepReadOnlyDetay') ,
              name: 'GeciciCalismaTalepReadOnlyDetayForTedarikci',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  //"Geçici Çalışma"
                  "Firmam - Talepler - Geçici Çalışma"
                ] }
            },
            {
              path: '/revize/:TalepId',
              component: () => import('pages/FirmaRelated/Firmam/Talepler/GeciciCalisma/GeciciCalismaTalepRevizeFormu') ,
              name: 'GeciciCalismaTalepRevizeFormuForTedarikci',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  //"Geçici Çalışma"
                  "Firmam - Talepler - Geçici Çalışma"
                ] }
            },
          ]),


        ]),

        { path: '/tedarikci-personel-bilgilerini-talep-eden-magazalar',
          component: () => import('pages/FirmaRelated/Firmam/MagazaOnay/MagazaPersonelBilgiTalepleriListesi'),
          name: 'MagazaPersonelBilgiTalepleriListesi',
          preventThisPageForSeen: true,
          meta: { requiresAuth: true, hasAtLeastOneOf: [
              "Firmam - Talepler - Geçici Çalışma"
            ] }
        },

      ]),
      // {
      //   path: '/test',
      //   component: () => import('pages/PropertyRelated/Raporlama/IdariRaporlar/TalepVeIsEmriRaporlari'),
      //   name: 'test',
      // },
      ...withPrefix('/m',[
        //Tesis Yönetimi
        ...withPrefix('/tesis-yonetimi',[
          // Sayac Yönetimi - Subpaths
          ...withPrefix('/sayac-yonetimi', [

            // Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/SayacYonetimi_Dashboard.vue'),
              name: 'SayacYonetimi_Dashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            // Kurum Sayac Listesi
            {
              path: '/kurum-sayac-listesi',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KurumSayaclari/KurumSayacListesiSekme') ,
              name: 'kurumSayacListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            // Kurum Sayac Oluştur
            {
              path: '/kurum-sayac-olustur',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KurumSayaclari/KurumSayacOlustur') ,
              name: 'kurumSayacOlustur',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            // Kurum Sayac Detay
            {
              path: '/kurum-sayac-listesi/sayac-detay/:sayacId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KurumSayaclari/KurumSayacDetay'),
              name: 'KurumSayacDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            {
              path: '/kurum-sayac-listesi/sayac-duzenle/:sayacId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KurumSayaclari/KurumSayacDuzenle'),
              name: 'KurumSayacDuzenle',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ] }
            },

            // Avm Sayac Listesi
            {
              path: '/avm-sayac-listesi',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/AvmSayaclari/AvmSayacListesiSekme') ,
              name: 'AvmSayacListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            // Avm Sayac Oluştur
            {
              path: '/avm-sayac-olustur',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/AvmSayaclari/AvmSayacOlustur') ,
              name: 'AvmSayacOlustur',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },
            // Avm Sayac Detay
            {
              path: '/avm-sayac-listesi/sayac-detay/:sayacId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/AvmSayaclari/AvmSayacDetay'),
              name: 'AvmSayacDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            {
              path: '/avm-sayac-listesi/sayac-duzenle/:sayacId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/AvmSayaclari/AvmSayacDuzenle'),
              name: 'AvmSayacDuzenle',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ] }
            },

            // Kiraci Sayac Listesi
            {
              path: '/kiraci-sayac-listesi',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KiraciSayaclari/KiraciSayacListesiSekme.vue') ,
              name: 'kiraciSayacListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            // Kiracı Sayac Oluştur
            {
              path: '/kiraci-sayac-olustur',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KiraciSayaclari/KiraciSayacOlustur.vue') ,
              name: 'kiraciSayacOlustur',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },

            // Kiraci Sayac Detay
            {
              path: '/kiraci-sayac-listesi/sayac-detay/:sayacId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KiraciSayaclari/KiraciSayacDetay'),
              name: 'KiraciSayacDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },
            {
              path: '/sayaclar/qr-sayac-detay/:sayacId',
              name: 'SayacDetayQrPage',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/SayacDetayQrPage.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]},
              preventThisPageForSeen: true,
            },
            {
              path: '/kiraci-sayac-listesi/sayac-duzenle/:sayacId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/KiraciSayaclari/KiraciSayacDuzenle'),
              name: 'KiraciSayacDuzenle',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ] }
            },

            // Okumalar
            {
              path: '/sayac-okumalar',
              component: () => import('pages/YapimAsamasinda') ,
              name: 'sayacOkumalar',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },
            // Sayac Raporlari
            {
              path: '/sayacRaporlari',
              component: () => import('pages/YapimAsamasinda') ,
              //component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/SayacRaporlari.vue'),
              name: 'sayacRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Düzenleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Ekleme",
                  "Tesis Yönetimi - Sayaç Yönetimi - Okumalar - Görüntüleme"
                ]
              }
            },
            // Tuketim Birim Fiyatlari
            {
              path: '/tuketimBirimFiyatlari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/SayacYonetimi/TuketimBirimFiyatListesi.vue'),
              name: 'TuketimBirimFiyatlari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Tesis Yönetimi-Tanımlamalar-Sayaç Yönetimi-Görüntüleme"
                ]
              }
            },

          ]),

          {
            path: '/fm-takvimi',
            name: 'FmTakvimi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/FMTakvimi.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini",'Tesis Yönetimi-FM Takvimi-Görüntüleme']},
            preventThisPageForSeen: true,
          },
          {
            path: '/bakim-onarim-listesi',
            name: 'BakimOnarimListesi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/BakimOnarimListesi.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini",'Tesis Yönetimi-Bakım Onarım Listesi-Görüntüleme']},
            preventThisPageForSeen: true,
          },
          {
            path: '/bakim-onarimlar',
            name: 'BakimOnarimlarSayfasi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/BakimOnarimlar.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini",'Tesis Yönetimi-Bakım Onarımlar-Bakım Çalışmaları-Görüntüleme']},
            preventThisPageForSeen: true,
          },
          {
            path: '/bakim-onarimlar/arsiv',
            name: 'BakimOnarimArsivSayfasi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/BakimOnarimArsiv.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini",'Tesis Yönetimi-Bakım Onarımlar-Arşiv-Görüntüleme']},
            preventThisPageForSeen: true,
          },
          {
            path: '/bakim-onarimlar/bakim-calismalari',
            name: 'BakimCalismalariSayfasi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/BakimCalismalari.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini",'Tesis Yönetimi-Bakım Onarımlar-Bakım Çalışmaları-Görüntüleme']},
            preventThisPageForSeen: true,
          },
          ...withPrefix('/ekipman-ust-gruplari',[
            {
              path: '',
              name: 'EkipmanUstGruplariListesi',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/EkipmanUstGruplariListesi.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini",'Tesis Yönetimi-Demirbaş ve Ekipmanlar-Görüntüleme']},
              preventThisPageForSeen: true,
            },
            {
              path: '/:ekipmanUstGrubuId/ekipman-gruplari',
              name: 'EkipmanGruplariListesi',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/EkipmanGruplariListesi.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Tesis Yönetimi-Demirbaş ve Ekipmanlar-Görüntüleme','Sistem Admini']},
              preventThisPageForSeen: true,
            },
            {
              path: '/:ekipmanUstGrubuId/ekipmanlar',
              name: 'EkipmanlarSayfasi',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/EkipmanlarDashboard.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Tesis Yönetimi-Demirbaş ve Ekipmanlar-Görüntüleme','Sistem Admini']},
              preventThisPageForSeen: true,
            },
          ]),

          {
            path: '/ekipmanlar/:ekipmanId/qr-ekipman-detay',
            name: 'EkipmanQrSayfasi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/EkipmanDetayQrPage.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ['Tesis Yönetimi-Demirbaş ve Ekipmanlar-Ekipman QR Detay-Görüntüleme','Sistem Admini']},
            preventThisPageForSeen: true,
          },
          {
            path: '/ekipmanlar/:ekipmanId/ekipman-detay',
            name: 'EkipmanDetaySayfasi',
            component: () => import('pages/PropertyRelated/TesisYonetimi/Ekipmanlar/EkipmanDetay.vue'),
            meta: { requiresAuth: true, hasAtLeastOneOf: ['Sistem Admini','Tesis Yönetimi-Demirbaş ve Ekipmanlar-Ekipman Detay-Görüntüleme']},
            preventThisPageForSeen: true,
          },

          ...withPrefix('/tanimlamalar',[
            {
              path: '',
              name: 'TesisYonetimiTanimlamalarDashboard',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Tanimlamalar/TesisYonetimiTanimlamalarDashboard.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Tesis Yönetimi-Tanımlamalar-Sayaç Yönetimi-Görüntüleme','Tesis Yönetimi-Tanımlamalar-Ekipman Grupları-Görüntüleme',"Tesis Yönetimi - Sayaç Yönetimi - Sayaçlar - Görüntüleme",'Sistem Admini']},
              preventThisPageForSeen: true,
            },

            {
              path: '/ekipman-gruplari',
              name: 'EkipmanGruplari',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Tanimlamalar/EkipmanGrubuSecimiListesi.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Tesis Yönetimi-Tanımlamalar-Ekipman Grupları-Görüntüleme','Sistem Admini'] },
              preventThisPageForSeen: true,
            },
            {
              path: '/kontrol-formlari',
              name: 'KontrolFormlariDashboard',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Tanimlamalar/KontrolFormlari/KontrolFormlariDashboard.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Sistem Admini','Tesis Yönetimi-Tanımlamalar-Kontrol Formları-Görüntüleme'] },
              preventThisPageForSeen: true,
            },
            {
              path: '/kontrol-formlari/kontrol-formu-olustur',
              name: 'KontrolFormuOlustur',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Tanimlamalar/KontrolFormlari/KontrolFormuOlusturmaSayfasi.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Sistem Admini','Tesis Yönetimi-Tanımlamalar-Kontrol Formları-Ekleme Düzenleme Silme'] },
              preventThisPageForSeen: true,
            },
            {
              path: '/kontrol-formlari/kontrol-formu-duzenle/:KontrolFormuId',
              name: 'KontrolFormuDuzenle',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Tanimlamalar/KontrolFormlari/KontrolFormuDuzenlemeSayfasi.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Sistem Admini','Tesis Yönetimi-Tanımlamalar-Kontrol Formları-Ekleme Düzenleme Silme'] },
              preventThisPageForSeen: true,
            },
            {
              path: '/kontrol-formlari/kontrol-formu-goruntule/:KontrolFormuId',
              name: 'KontrolFormuGoruntule',
              component: () => import('pages/PropertyRelated/TesisYonetimi/Tanimlamalar/KontrolFormlari/KontrolFormuGoruntulemeSayfasi.vue'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ['Sistem Admini','Tesis Yönetimi-Tanımlamalar-Kontrol Formları-Görüntüleme'] },
              preventThisPageForSeen: true,
            }
          ]),


        ]),

        // İnsan Yönetimi - Subpaths
        ...withPrefix('/insan-yonetimi',[

          // Yönetim Personeli - Subpaths
          ...withPrefix('/yonetim-personeli',[

            // Yönetim Personeli Dashboard
            {
              path: '',
              name: 'YonetimPersoneli_Dashboard',
              component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/AvmPersonelYonetimi_Dashboard'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                "İnsan Yönetimi - Yönetim Personeli - Personel Listesi - Görüntüleme",
                "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Görüntüleme",
                "İnsan Yönetimi - Yönetim Personeli - AVM Personel Giriş - Görüntüleme"] },
            },

            // Personel Listesi - Subpaths
            ...withPrefix('/personel-listesi', [

              {
                path: '',
                name: 'YonetimPersoneliSekme',
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelListesi/YonetimPersoneliSekme'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Yönetim Personeli - Personel Listesi - Görüntüleme"] },
              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'YonetimPersonelDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelListesi/Personel'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Yönetim Personeli - Personel Listesi - Görüntüleme"] },
              },

            ]),

            // Personel Arşiv Listesi - Subpaths
            ...withPrefix('/personel-arsiv-listesi', [

              {
                path: '',
                name: 'YonetimPersoneliArsivListesi',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelListesi/YonetimPersoneliArsivListesi'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Yönetim Personeli - Personel Listesi - Görüntüleme"] },
              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'YonetimPersonelDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelListesi/Personel'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Yönetim Personeli - Personel Listesi - Görüntüleme"] },
              },

            ]),

            // Personel Ekleme Süreçleri - Subpaths
            ...withPrefix('/personel-ekleme-surecleri', [

              // Personel Ekleme Süreçleri Listesi
              {
                path: '',
                name: 'YonetimPersoneliEklemeSurecleriListesi',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelEklemeSurecleri/DavetliPersoneller'),
                meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Görüntüleme",
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Ekleme",
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Form Onaylama"] },
              },

              // Yönetim Personeli Ekleme Süreci - Olustur
              {
                path: '/form-olustur',
                name: 'YonetimPersoneliEklemeSureciOlustur',
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelEklemeSurecleri/personelDavet'),
                meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Ekleme",
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Form Onaylama"] },
              },

              // Yönetim Personeli Ekleme Süreci - Detay & Yönetim Onay
              {
                path: '/:enrollment_id',
                name: 'YonetimPersoneliEklemeSureciDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelEklemeSurecleri/personelDavetDetay'),
                meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Görüntüleme",
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Ekleme",
                  "İnsan Yönetimi - Yönetim Personeli - Personel Davetleri - Form Onaylama"] },
              },

            ]),

            // Giriş/Çıkış Listesi
            {
              path: '/giris',
              component: () => import('pages/PropertyRelated/InsanYonetimi/YonetimPersoneli/PersonelGirisCikis/PersonelGirisCikis'),
              name: 'YonetimPersoneliGirisCikis',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Yönetim Personeli - AVM Personel Giriş - Görüntüleme"] }
            },

          ]),

          // Tedarikçi Personeli - Subpaths
          ...withPrefix('/tedarikci-personeli',[

            // Tedarikçi Personeli Dashboard
            {
              path: '',
              name: 'TedarikciPersoneli_Dashboard',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/TedarikciPersonelYonetimi_Dashboard'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme",
                  "İnsan Yönetimi - Tedarikçi Personeli - Personel Davetleri - Görüntüleme",
                  "İnsan Yönetimi - Tedarikçi Personeli - AVM Personel Giriş - Görüntüleme"] },
            },

            // Personel Listesi - Subpaths
            ...withPrefix('/personel-listesi', [

              // Personel Listesi
              /*{
                path: '',
                name: 'TedarikciPersoneliListesi',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelListesi/PersonelListesi'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme"] },

              },*/
              {
                path: '',
                name: 'TedarikciPersoneliSekme',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelListesi/TedarikciPersoneliSekme'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme"] },

              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'TedarikciPersonelDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelListesi/Personel'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme"] },

              },

            ]),

            // Personel Arşiv Listesi - Subpaths
            ...withPrefix('/personel-arsiv-listesi', [

              // Personel Listesi
              /*{
                path: '',
                name: 'TedarikciPersoneliListesi',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelListesi/PersonelListesi'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme"] },

              },*/
              {
                path: '',
                name: 'TedarikciPersoneliArsivListesi',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelListesi/TedarikciPersoneliArsivListesi'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme"] },

              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'TedarikciPersonelDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelListesi/Personel'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Personel Listesi - Görüntüleme"] },

              },

            ]),

            // Personel Ekleme Süreçleri - Subpaths
            ...withPrefix('/personel-ekleme-surecleri', [

              // Personel Ekleme Süreçleri Listesi
              {
                path: '',
                name: 'TedarikciPersoneliEklemeSurecleriListesi',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelEklemeSurecleri/DavetliPersoneller'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Görüntüleme",
                    "İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Ekleme",
                    "İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Form Onaylama"] },

              },

              // Tedarikçi Personeli Ekleme Süreci - Olustur
              {
                path: '/form-olustur',
                name: 'TedarikciPersoneliEklemeSureciOlustur',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelEklemeSurecleri/personelDavet'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Ekleme",
                    "İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Form Onaylama"] },

              },

              // Tedarikçi Personeli Ekleme Süreci - Detay & Yönetim Onay
              {
                path: '/:tedarikci_personel_kayit_id',
                name: 'TedarikciPersoneliEklemeSureciDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelEklemeSurecleri/personelDavetDetay'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Görüntüleme",
                    "İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Ekleme",
                    "İnsan Yönetimi - Tedarikçi Personeli - Ekleme Süreçleri - Form Onaylama"] },

              },

            ]),

            // Giriş/Çıkış Listesi
            {
              path: '/giris',
              component: () => import('pages/PropertyRelated/InsanYonetimi/TedarikciPersoneli/PersonelGirisCikis/PersonelGirisCikis'),
              name: 'TedarikciPersoneliGirisCikis',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tedarikçi Personeli - Giriş/Çıkış - Görüntüleme"] }
            },


          ]),
          // Kiracı Personeli - Subpaths
          ...withPrefix('/kiraci-personeli',[

            // Kiracı Personeli Dashboard
            {
              path: '',
              name: 'MagazaPersoneli_Dashboard',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/MagazaPersonelYonetimi_Dashboard'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [] },
            },
            // Personel Listesi - Subpaths
            ...withPrefix('/personel-listesi', [

              // Personel Listesi
              {
                path: '',
                name: 'KiraciPersoneliSekme',
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciPersoneliSekme'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Personel Listesi - Görüntüleme"] },
              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'KiraciPersonelDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciPersonelDetay'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Personel Listesi - Görüntüleme"] },

              },

            ]),
            // Personel Arşiv Listesi - Subpaths
            ...withPrefix('/personel-arsiv-listesi', [

              {
                path: '',
                name: 'KiraciPersoneliArsivListesi',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciPersoneliArsivListesi'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Personel Listesi - Görüntüleme"] },
              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'KiraciPersonelDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciPersonelDetay'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Personel Listesi - Görüntüleme"] },

              },

            ]),
            // Personel Ekleme Süreçleri - Subpaths
            ...withPrefix('/personel-ekleme-surecleri', [

              // Personel Ekleme Süreçleri Listesi
              {
                path: '',
                name: 'KiraciPersoneliEklemeSurecleriListesi',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/PersonelEklemeSurecleri/DavetliPersoneller'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Görüntüleme",
                    "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Ekleme",
                    "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Form Onaylama"] },

              },

              // Kiracı Personeli Ekleme Süreci - Olustur
              {
                path: '/form-olustur',
                name: 'KiraciPersoneliEklemeSureciOlustur',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/PersonelEklemeSurecleri/personelDavet'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Ekleme",
                    "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Form Onaylama"] },

              },

              // Kiracı Personeli Ekleme Süreci - Detay & Yönetim Onay
              {
                path: '/:kiraci_personel_kayit_id',
                name: 'KiraciPersoneliEklemeSureciDetay',
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/PersonelEklemeSurecleri/personelDavetDetay'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Görüntüleme",
                    "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Ekleme",
                    "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Form Onaylama"] },

              },

            ]),

            // Giriş/Çıkış Listesi
            {
              path: '/giris',
              component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/PersonelGirisCikis'),
              name: 'KiraciPersoneliGirisCikis',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Giriş/Çıkış - Görüntüleme"] }
            },

            // Kiraci Tedarikci Personeli Listesi - Subpaths
            ...withPrefix('/tedarikci-personeli', [


              // AVMdeki Kiracı Tedarikçi Personel Listesi
              {
                path: '/personel-listesi',
                name: 'KiraciTedarikciPersoneliListesiForAVM',
                preventThisPageForSeen: true,
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciTedarikciPersonel/PersonelListesi/PersonelListesi'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Personel Listesi - Görüntüleme"] },
              },

              // Personel Detay
              {
                path: '/:personelID',
                name: 'KiraciTedarikciPersonelDetayForAVM',
                component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciTedarikciPersonel/PersonelListesi/Personel'),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Personel Listesi - Görüntüleme"] },

              },

              // Kiracı Tedarikçi Ekleme Süreçleri - Subpaths
              ...withPrefix('/ekleme-surecleri', [


                // Personel Ekleme Süreçleri Listesi
                {
                  path: '',
                  name: 'KiraciTedarikciPersoneliEklemeSurecleriListesiForAVM',
                  preventThisPageForSeen: true,
                  component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciTedarikciPersonel/PersonelEklemeSurecleri/DavetliPersoneller'),
                  meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Görüntüleme",
                      "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Ekleme",
                      "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Form Onaylama"] },

                },

                // Kiracı Personeli Ekleme Süreci - Detay & Yönetim Onay
                {
                  path: '/:kiraci_tedarikci_personel_kayit_id',// used to be kiraci_personel_kayit_id
                  name: 'KiraciTedarikciPersoneliEklemeSureciDetayForAVM',
                  component: () => import('pages/PropertyRelated/InsanYonetimi/KiraciPersoneli/KiraciTedarikciPersonel/PersonelEklemeSurecleri/personelDavetDetay'),
                  meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Görüntüleme",
                      "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Ekleme",
                      "İnsan Yönetimi - Kiracı Personeli - Ekleme Süreçleri - Form Onaylama"] },
                },

              ]),

            ]),

          ]),



          // Geçici Çalışma - Subpaths
          ...withPrefix('/gecici-calisma',[

            // Geçici Çalışma Dashboard
            {
              path: '',
              name: 'GeciciCalisma_Dashboard',
              component: () => import('pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaYonetimi_Dashboard'),
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                "İnsan Yönetimi - Geçici Çalışma - Geçici Çalışma Listesi - Görüntüleme",
                "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Görüntüleme",
                "İnsan Yönetimi - Geçici Çalışma - Geçici Giriş - Görüntüleme"
                ] },
            },

            // Ekleme ve Onay - Subpaths
            ...withPrefix('/ekleme-ve-onay',[

              { path: '', // ARTIK BU SAYFAYA DÜŞMEMELİYİZ
              component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaTalepleriListesi'),
              name: 'GeciciCalismaTalepleriListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Görüntüleme",
                  "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Ekleme",
                  "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Talep Detay - Görüntüleme",
                  "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Talep Detay - Onayla Reddet Revize Talep Et",
                  "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Talep Detay - Revize Et",
                  "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Talep Detay - İptal Et",
                ] }
              },

              { path: '/gecici-calisma-talep-olustur',
                component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaTalepOlusturForAVM'),
                name: 'GeciciCalismaTalepOlusturForAVM',
                meta: { requiresAuth: true, hasAtLeastOneOf: [
                    "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Ekleme"
                  ] }
              },
              ...withPrefix('/gecici-calisma-talep-detay',[
                {
                  path: '/:TalepId',
                  component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaTalepDetay') ,
                  name: 'GeciciCalismaTalepDetayForAVM',
                  meta: { requiresAuth: true, hasAtLeastOneOf: [
                      "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Talep Detay - Görüntüleme",
                    ] }
                },
                {
                  path: '/revize/:TalepId',
                  component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaTalepRevizeFormuForAVM') ,
                  name: 'GeciciCalismaTalepRevizeFormuForAVM',
                  meta: { requiresAuth: true, hasAtLeastOneOf: [
                      "İnsan Yönetimi - Geçici Çalışma - Ekleme ve Onay Listesi - Talep Detay - Revize Et",
                    ] }
                },
              ])

            ]),

            // Geçici Çalışma Listeleri Sekme
            { path: '/calisma-listesi',
              component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/AvmGeciciCalismaListeleriSekme'),
              name: 'AvmGeciciCalismaListeleriSekme',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Geçici Çalışma - Geçici Çalışma Listesi - Görüntüleme"
                ] }
            },
            {
                path: '/:TalepId',
                component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaDetay') ,
                name: 'GeciciCalismaDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: [
                    // Detay Goruntuleme yetkisine gerek duyulmadi
                    "İnsan Yönetimi - Geçici Çalışma - Geçici Çalışma Listesi - Görüntüleme",
                  ] }
              },

            // Geçici Giriş
            { path: '/personel-girisleri',
              component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/PersonelGeciciGirisleri'),
              name: 'PersonelGeciciGirisleri',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Geçici Çalışma - Geçici Giriş - Görüntüleme"
                ] }
            },

            // Geçici Çalışma Arşiv
            { path: '/arsiv',
              component: () => import('src/pages/PropertyRelated/InsanYonetimi/GeciciCalisma/GeciciCalismaArsivListesi'),
              name: 'GeciciCalismaArsivListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Geçici Çalışma - Geçici Çalışma Listesi - Görüntüleme"
                ] }
            },


          ]),

          // Tanımlamalar - Subpaths
          ...withPrefix('/tanimlamalar', [

            // Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/InsanYonetimiTanimlamalar_Dashboard'),
              name: 'InsanYonetimiTanimlamalar_Dashboard',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "İnsan Yönetimi - Tanımlamalar - Pozisyonlar ve Yetkiler - Görüntüleme",
                  "İnsan Yönetimi - Tanımlamalar - Zorunlu Form Bilgileri - Düzenleme",
                  "İnsan Yönetimi - Tanımlamalar - Avm Personel Giriş Saatleri - Düzenleme"] }
            },

            // Zorunlu Form Bilgileri
            {
              path: '/zorunlu-form-bilgileri',
              component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/PersonelEklemeFormlariZorunluAlanlari/AvmCalisanZorunluAlanlar'),
              name: 'ZorunluFormBilgileri',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tanımlamalar - Zorunlu Form Bilgileri - Düzenleme"] }
            },

            // Vardiya
            {
              path: '/vardiya',
              component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/VardiyaYonetimi/VardiyaPlanlariSekme'),
              name: 'VardiyaPlanlariSekme',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tanımlamalar - Vardiya Planları - Görüntüleme"] }
            },
            {
              path: '/vardiya-personel-detay/:userId',
              component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/VardiyaYonetimi/VardiyaPersonelDetay'),
              name: 'VardiyaPersonelDetay',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tanımlamalar - Vardiya Planları - Görüntüleme"] }
            },
            {
              path: '/vardiya-takvimi',
              component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/VardiyaYonetimi/VardiyaTakvimi'),
              name: 'VardiyaTakvimi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Çalışma Takvimi - Görüntüleme"] }
            },

            // Pozisyonlar - Subpaths
            ...withPrefix('/pozisyonlar', [

              // Pozisyon Listesi
              {
                path: '',
                component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/PozisyonlarVeYetkiler/AvmPozisyonlari'),
                name: 'AvmPozisyonlari',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tanımlamalar - Pozisyonlar ve Yetkiler - Görüntüleme"] }
              },

              // Pozisyon - Düzenle
              {
                path: '/:PozisyonID',//pozisyonRowID',
                component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/PozisyonlarVeYetkiler/pozisyonYetkiKonuDuzenle'),
                name: 'PozisyonYetkiVeKonuDuzenle',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tanımlamalar - Pozisyonlar ve Yetkiler - Ekleme Düzenleme Silme"] }
              },
            ]),

            // Avm Personel Giriş Saatleri
            {
              path: '/avm-personel-giris-saatleri',
              component: () => import('pages/PropertyRelated/InsanYonetimi/Tanimlamalar/AvmPersoneliGirisSaatleri/AvmPersonelGirisSaatleri'),
              name: 'AvmPersonelGirisSaatleri',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["İnsan Yönetimi - Tanımlamalar - Avm Personel Giriş Saatleri - Düzenleme"] }
            },

          ])

        ]),


        // Operasyon Yönetimi - Subpaths
        ...withPrefix('/operasyon-yonetimi', [

          // İş Emri ve Talep Yönetimi - Subpaths
          ...withPrefix('/is-emri-ve-talep-yonetimi', [

            // Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/IsEmriVeTalepYonetimi/IsEmriVeTalepYonetimi_Dashboard'),
              name: 'IsEmriVeTalepYonetimi_Dashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - İş Emri ve Talep Yönetimi - Taleplerim - Görüntüleme",
                  "Operasyon Yönetimi - İş Emri ve Talep Yönetimi - İş Emirlerim"
                ]
              }
            },

            ...withPrefix('/taleplerim', [
              {
                path: '',
                component: () => import('pages/PropertyRelated/OperasyonYonetimi/IsEmriVeTalepYonetimi/Taleplerim/Taleplerim') ,
                name: 'Taleplerim',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Operasyon Yönetimi - İş Emri ve Talep Yönetimi - Taleplerim - Görüntüleme", "Mağazam - Talep İşlemleri","İş Emri"] }
              },
              {
                path: '/talep-olustur',
                component: () => import('pages/PropertyRelated/OperasyonYonetimi/IsEmriVeTalepYonetimi/Taleplerim/talepOlustur'),
                name: 'TalepOlusturma',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Operasyon Yönetimi - İş Emri ve Talep Yönetimi - Taleplerim - Ekleme","İş Emri"] }
              },
            ]),

            ...withPrefix('/calisma-talepleri', [
              {
                path: '',
                component: () => import('pages/PropertyRelated/OperasyonYonetimi/IsEmriVeTalepYonetimi/IsEmirlerim/TalepListesiSekme') ,
                name: 'TalepListesi',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Operasyon Yönetimi - İş Emri ve Talep Yönetimi - İş Emirlerim","İş Emri"] }
              },
              {
                path: '/:TalepID',//TalepRowID',
                component: () => import('pages/PropertyRelated/OperasyonYonetimi/IsEmriVeTalepYonetimi/IsEmirlerim/CalismaTalepDetay'),
                name: 'CalismaTalepDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Operasyon Yönetimi - İş Emri ve Talep Yönetimi - İş Emirlerim","İş Emri"] }
              },
            ])

          ]),

          // Mal Kabul Yönetimi - Subpaths
          ...withPrefix('/mal-kabul-yonetimi', [

            // Mal Kabul Listesi
            {
              path: '',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/MalKabulYonetimi/MalKabulListesi'),
              name: 'MalKabulListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Görüntüleme",
                ]
              }
            },

            // Mal Kabul - Detay
            {
              path: '/:MalKabulDetayID',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/MalKabulYonetimi/MalKabulDetay'),
              name: 'MalKabulDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Görüntüleme",
                ]
              }
            },

            // Mal Kabul - Oluştur
            {
              path: '/mal-kabul-olustur',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/MalKabulYonetimi/YeniMalKabul'),
              name: 'MalKabulOlustur',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Ekleme Düzenleme Silme",
                ]
              }
            },

            // Mal Kabul - Oluştur
            {
              path: '/mal-kabul-olustur/:Id',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/MalKabulYonetimi/YeniMalKabul'),
              name: 'RouteParamsIleMalKabulOlustur',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Mal Kabul Yönetimi - Mal Kabuller - Ekleme Düzenleme Silme",
                ]
              }
            },

          ]),


          // Güvenlik Yönetimi - Subpaths
          ...withPrefix('/guvenlik-yonetimi', [

            // Dashboard
            {
              /*path: '',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Frekans_Dashboard'),
              name: 'Frekanslar_Dashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Pazarlama Yönetimi - Frekanslar - Ziyaretçi - Görüntüleme",
                  "Pazarlama Yönetimi - Frekanslar - Araç - Görüntüleme",
                  "Pazarlama Yönetimi - Frekanslar - Turist - Görüntüleme"
                ] }*/
            },

            // Güvenlik Operasyonu Listesi
            {
              path: '/guvenlikOperasyonuListesi',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/GuvenlikOperasyonu/GuvenlikOperasyonuListesiSekme') ,
              name: 'guvenlikOperasyonuListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Güvenlik Yönetimi - Tutanak Listesi - Görüntüleme",
                ]
              }
            },

            // Güvenlik Operasyonu Oluştur
            {
              path: '/guvenlikOperasyonuOlustur',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/GuvenlikOperasyonu/GuvenlikOperasyonuOlustur') ,
              name: 'guvenlikOperasyonuOlustur',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Güvenlik Yönetimi - Tutanak Listesi - Ekleme",
                ]
              }
            },

            // Güvenlik Operasyonu Detay
            {
              path: '/guvenlikOperasyonuDetay/:guvenlikOperasyonuId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/GuvenlikOperasyonu/GuvenlikOperasyonuDetay') ,
              name: 'guvenlikOperasyonuDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Operasyon Yönetimi - Güvenlik Yönetimi - Tutanak Listesi - Görüntüleme",
                ]
              }
            },


          ]),



        ]),

        // Pazarlama Yönetimi - Subpaths
        ...withPrefix('/pazarlama-yonetimi', [
          // Ziyaretçi Frekansı - Subpaths
          ...withPrefix('/frekanslar', [

            // Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Frekans_Dashboard'),
              name: 'Frekanslar_Dashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Pazarlama Yönetimi - Frekanslar - Ziyaretçi - Görüntüleme",
                  "Pazarlama Yönetimi - Frekanslar - Araç - Görüntüleme",
                  "Pazarlama Yönetimi - Frekanslar - Turist - Görüntüleme",
                  "Pazarlama Yönetimi - Frekanslar - Sinema - Görüntüleme"
                ] }
            },

            // Ziyaretçi Frekansları
            {
              path: '/ziyaretci-frekanslari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Ziyaretci/ZiyaretciFrekanslari') ,
              name: 'ZiyaretciFrekanslari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Pazarlama Yönetimi - Frekanslar - Ziyaretçi - Görüntüleme",
                ] }
            },

            {
              path: '/gunluk-ziyaretci-frekanslari/:period',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Ziyaretci/GunlukZiyaretciFrekanslari') ,
              name: 'GunlukZiyaretciFrekanslari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Pazarlama Yönetimi - Frekanslar - Ziyaretçi - Görüntüleme",
                ] }
            },

            {
              path: '/ziyaretci-frekanslari-ve-hedefleri',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Ziyaretci/ZiyaretciFrekanslariSekme') ,
              name: 'ZiyaretciFrekanslariSekme',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Pazarlama Yönetimi - Frekanslar - Ziyaretçi - Görüntüleme",
                ] }
            },

            {
              path: '/ziyaretci-frekansi-detay/:dateId',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Ziyaretci/ZiyaretciFrekansiDetay') ,
              name: 'ZiyaretciFrekansiDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Pazarlama Yönetimi - Frekanslar - Ziyaretçi - Görüntüleme",
                ] }
            },

            // Araç Frekansları
            {
              path: '/arac-frekanslari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Arac/AracFrekanslari') ,
              name: 'AracFrekanslari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Pazarlama Yönetimi - Frekanslar - Araç - Görüntüleme"] }
            },

            // Turist Frekansları
            {
              path: '/turist-frekanslari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Turist/TuristFrekanslari') ,
              name: 'TuristFrekanslari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Pazarlama Yönetimi - Frekanslar - Turist - Görüntüleme"] }
            },

            // Sinema Frekansları
            {
              path: '/sinema-frekanslari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/Sinema/SinemaFrekanslari') ,
              name: 'SinemaFrekanslari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Pazarlama Yönetimi - Frekanslar - Sinema - Görüntüleme"] }
            },
            // SG Frekansları
            {
              path: '/sg-frekanslari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/SGFrekansi/SGFrekanslari.vue') ,
              name: 'SGFrekanslari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Pazarlama Yönetimi - Frekanslar - Sinema - Görüntüleme"] }
            },
            // Eğlence Alanı Frekansları
            {
              path: '/eglence-alani-frekanslari',
              component: () => import('pages/PropertyRelated/OperasyonYonetimi/Frekanslar/EglenceAlani/EglenceAlaniFrekanslari') ,
              name: 'EglenceAlaniFrekanslari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Pazarlama Yönetimi - Frekanslar - Eğlence Alanı - Görüntüleme"] }
            },

          ]),
          // Duyuru - Subpaths
          ...withPrefix('/duyuru', [
            {
              path: '',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Duyuru/DuyuruSekme.vue') ,
              name: 'DuyuruSekme',
              meta: { requiresAuth: true,
                hasAtLeastOneOf: [] //["Pazarlama Yönetimi - Duyuru - Görüntüleme"]
              }
            },
            { path: '/duyuruOlustur',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Duyuru/DuyuruOlustur') ,
              name: 'duyuruOlustur',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [] }
            },
            { path: '/duyuruDuzenle/:duyuruId',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Duyuru/DuyuruDuzenle') ,
              name: 'duyuruDuzenle',
              meta: { requiresAuth: true, hasAtLeastOneOf: [] }
            },
            { path: '/duyuruDetay/:duyuruId',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Duyuru/DuyuruDetay') ,
              name: 'duyuruDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: [] }
            },
          ]),

          // Anket - Subpaths
          ...withPrefix('/anket', [
            {
              path: '',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Anket/AnketSekme') ,
              name: 'AnketListesi',
              meta: { requiresAuth: true,
                hasAtLeastOneOf: []//["Pazarlama Yönetimi - Anket - Görüntüleme"]
              }
            },
            { path: '/anketOlustur',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Anket/AnketOlustur') ,
              name: 'anketOlustur',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [] }
            },
            { path: '/anketDuzenle/:anketId',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Anket/AnketDuzenle') ,
              name: 'anketDuzenle',
              meta: { requiresAuth: true, hasAtLeastOneOf: [] }
            },
            { path: '/anketSonuc/:anketId',
              component: () => import('pages/PropertyRelated/PazarlamaYonetimi/Anket/AnketSonuclari') ,
              name: 'anketSonuc',
              meta: { requiresAuth: true, hasAtLeastOneOf: [] }
            },

          ]),

        ]),

        // Kiracı Yönetimi - Subpaths
        ...withPrefix('/kiraci-yonetimi', [
          // Kiracı Dashboard
          {
            path: '',
            component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/KiracilarDashboard'),
            name: 'KiracilarDashboard',
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Listesi - Görüntüleme"] }
          },
          ...withPrefix('/kiraci-arsiv-listesi',[
            // Kiracı Arşiv Listesi
            {
              path: '',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/MagazaArsivListesi'),
              name: 'kiraciArsivListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Listesi - Görüntüleme"] }
            },
              // Kiracı - Detay
              {
                path: '/:magazaID',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/MagazaArsivDetay'),
                name: 'kiraciArsivDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Listesi - Görüntüleme"] }
              }
            ]),
          ...withPrefix('/kiraci-listesi', [

            // Kiracı Listesi
            {
              path: '',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/MagazaListesi'),
              name: 'kiraciListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Listesi - Görüntüleme"] }
            },

            // Kiracı - Detay
            {
              path: '/:magazaID',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/MagazaDetay'),
              name: 'kiraciDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Listesi - Görüntüleme"] }
            }

          ]),

          ...withPrefix('/kiraci-cirolari', [
            {
              path: '',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroDashboard'),
              name: 'CiroDashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },
            // Ciro Listesi
            {
              path: '/ciro-listesi',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/KiraciCirolariListesi') ,
              name: 'CiroListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },
            {
              path: '/ciro-arsiv-listesi',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroArsivListesi') ,
              name: 'CiroArsivListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },

            // Ciro - Detay
            {
              path: '/:MagazaID',//'/:CiroID',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroDetay'),
              name: 'CiroDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },

            // Ciro - Oluştur
            {
              path: '/ciro-bilgisi-olustur',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroEkleme'),
              name: 'CiroEkleme',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Ciro Ekleme"] }
            },

            // Ciro - Oluştur (with MagazaID)
            {
              path: '/ciro-bilgisi-olustur/:MagazaID',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroEkleme'),
              name: 'CiroEklemeWithMagazaID',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Ciro Ekleme"] }
            },

            //Hedefler
            {
              path: '/ciro-hedefleri',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroHedefleri/CiroHedefTanimlamalariSekme'),
              name: 'CiroHedefTanimlamalariSekme',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Ciro Ekleme"] }
            },

            {
              path: '/ciro-hedef-detay/:MagazaID',//'/:CiroID',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/CiroListesi/CiroHedefleri/CiroHedefDetay'),
              name: 'CiroHedefDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Ciro Ekleme"] }
            },



          ]),
          ...withPrefix('/magaza-ciro-listesi', [

            {
              path: '',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/SozlesmesizCiroListesi/SozlesmesizCiroDashboard'),
              name: 'SozlesmesizCiroDashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },
            // Ciro Listesi
            {
              path: '/ciro-listesi',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/SozlesmesizCiroListesi/SozlesmesizCiroListesi') ,
              name: 'SozlesmesizCiroListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },
            {
              path: '/ciro-arsiv-listesi',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/SozlesmesizCiroListesi/SozlesmesizCiroArsivListesi') ,
              name: 'SozlesmesizCiroArsivListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },
            // Ciro - Detay
            {
              path: '/:MagazaID',//'/:CiroID',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/SozlesmesizCiroListesi/SozlesmesizCiroDetay'),
              name: 'SozlesmesizCiroDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Görüntüleme"] }
            },
            {
              path: '/ciro-bilgisi-olustur/:MagazaID',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/SozlesmesizCiroListesi/SozlesmesizCiroEkleme'),
              name: 'SozlesmesizCiroEkleme',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Ciro Listesi - Ciro Ekleme"] }
            },
          ]),


          // Kiracı Sözleşmeleri - Subpaths
          ...withPrefix('/kiraci-sozlesmeleri', [

            // Kiracı Sözleşmeleri Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/KiraciSozlesmeleriDashboard'),
              name: 'KiraciSozlesmeleri_Dashboard',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
            },

            // Sözleşmeler
            ...withPrefix('/sozlesmeler', [
              // Kiracı Sözleşmeleri Listesi
              {
                path: '',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/SozlesmeListeleriSekme'),
                name: 'KiraciSozlesmeleriListesi',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
              },

              // Kiracı Sözleşmesi - Oluştur
              {
                path: '/sozlesme-olustur',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/SozlesmeKaydetme/SozlesmeKaydetme'),
                name: 'KiraciSozlesmesiOlustur',
                props: route => ({ query: {
                    'kiraciId': route.query.kiraciId,
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/ana-sozlesme-duzenle',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/SozlesmeKaydetme/SozlesmeKaydetme'),
                name: 'AnaSozlesmeDuzenle',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/tadilat-ek-protokol-olustur',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/EkProtokolKaydetme'),
                name: 'EkProtokolOlustur',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId,
                    'EkProtokolNo':route.query.EkProtokolNo
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/tadilat-ek-protokol-duzenle',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/EkProtokolKaydetme'),
                name: 'EkProtokolDuzenle',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId,
                    'EkProtokolId':route.query.EkProtokolId
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/ek-protokol-detay',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/EkProtokolDetay'),
                name: 'EkProtokolDetay',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId,
                    'EkProtokolNo':route.query.EkProtokolNo
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
              },
              {
                path: '/uzatma-ek-protokol-olustur',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/EkProtokolKaydetme'),
                name: 'UzatmaEkProtokolOlustur',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId,
                    'EkProtokolNo':route.query.EkProtokolNo
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/uzatma-ek-protokol-duzenle',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/EkProtokolKaydetme'),
                name: 'UzatmaEkProtokolDuzenle',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId,
                    'EkProtokolId':route.query.EkProtokolId
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/fesih-protokolu-olustur',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/FesihProtokoluKaydetme'),
                name: 'FesihProtokoluKaydetme',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/fesih-protokolu-duzenle',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/FesihProtokoluDuzenleme'),
                name: 'FesihProtokoluDuzenleme',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Ekleme Düzenleme Silme"] }
              },
              {
                path: '/fesih-protokolu-detay',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/EkProtokolKaydetme/FesihProtokoluDetay'),
                name: 'FesihProtokoluDetay',
                props: route => ({ query: {
                    'SozlesmeId':route.query.SozlesmeId
                  }}),
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
              },

              // Kiracı Sözleşmesi - Detay
              {
                path: '/:SozlesmeId',
                component:()=>import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/SozlesmeDetay/SozlesmeDetay'),
                name: 'KiraciSozlesmeDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
              },
            ]),

            // Arşiv
            ...withPrefix('/arsiv',[
              // Arşivlenen Sözleşmeler Listesi
              {
                path: '',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/Arsiv/ArsivlenenSozlesmeListesi'),
                name: 'ArsivlenenSozlesmeListesi',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli
              },
            ]),

            // Dönemsel Uyarılar
            ...withPrefix('/donemsel-uyarilar',[
              // Dönemsel Uyarılar Listesi
              {
                path: '',
                component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciSozlesmeleri/DonemselUyarilar/SozlesmeDonemselUyariListeleriSekme'),
                name: 'SozlesmeDonemselUyarilarListesi',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Kiracı Yönetimi - Kiracı Sözleşmeleri - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
            ]),
            ]),



        ]),

        // Finansal Yönetim - Subpaths
        ...withPrefix('/finansal-yonetim', [

          //Tanımlamalar
          ...withPrefix('/tanimlamalar', [
            // Tanimlamalar Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/FinansalYonetimTanimlamalari_Dashboard'),
              name: 'FinansalYonetimTanimlamalari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },


            // {
            //   path: '/gider-tipleri',
            //   component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/AvmGiderTipleri'),//AvmGiderTipleri_OLD
            //   name: 'AvmGiderTipleri',
            //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            // },
            {
              path: '/gider-tanimlama',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/GiderTanimlamalari/GiderTanimlamalariSekme'),
              name: 'GiderTanimlamalariSekme',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
            {
              path: '/diger-gelir-tanimlama',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/DigerGelirTanimlamalari/DigerGelirTanimlamalariListesi.vue'),
              name: 'DigerGelirTanimlamalariListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },

            {
              path: '/on-odeme-m2-birim-fiyat-listesi',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/m2BirimFiyatListesi'),
              name: 'm2BirimFiyatListesi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },

            {
              path: '/reklam-ve-pazarlama-m2-birim-fiyat-listesi',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/ReklamVePazarlama_m2BirimFiyatListesi'),
              name: 'reklamVePazarlama_m2BirimFiyatListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
            {
              path: '/vergi-listesi',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/VergiListesi'),//AvmGiderTipleri_OLD
              name: 'vergiListesi',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
            {
              path: '/gunluk-kurlar',
              component: () => import('pages/PropertyRelated/FinansalYonetim/Tanimlamalar/DovizKurlari/GunlukKurlar/GunlukKurlarSekme') ,
              name: 'GunlukKurlarSekme',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
          ]),

          //Gider Yönetimi
          ...withPrefix('/gider-yonetimi', [
            // Gider Yonetimi Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/FinansalYonetim/GiderYonetimi/GiderYonetimiDashboard'),
              name: 'GiderYonetimi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },

            {
              path: '/giderler',
              component: () => import('pages/PropertyRelated/FinansalYonetim/GiderYonetimi/Giderler'),
              name: 'Giderler',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
            {
              path: '/gider-olustur',
              component: () => import('pages/PropertyRelated/FinansalYonetim/GiderYonetimi/GiderEkleme'),
              name: 'GiderEkleme',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
            {
              path: '/gider-duzenle/:giderId',
              component: () => import('pages/PropertyRelated/FinansalYonetim/GiderYonetimi/GiderDuzenle'),
              name: 'GiderDuzenle',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
            {
              path: '/gider-detay/:giderId',
              component: () => import('pages/PropertyRelated/FinansalYonetim/GiderYonetimi/GiderDetay'),
              name: 'GiderDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            },
          ]),

          //Gelir Yönetimi
          ...withPrefix('/gelir-yonetimi', [
            // Gelir Yonetimi Dashboard
            {//TODO:UNCOMMENT WHEN FİNİSHED
              path: '',
              component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/GelirYonetimiDashboard'),
              name: 'GelirYonetimi',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Görüntüleme"] }
            },
            ...withPrefix('/kira-gelirleri', [
              {
              path: '/birlesmis-tahakkuklar',
              component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/BirlesmisTahakkukSekme'),
              name: 'BirlesmisTahakkukSekme',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Tahakkuklar - Görüntüleme"] }
              // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
            },
              ]),
            // {
            //   path: '/giderler',
            //   component: () => import('pages/PropertyRelated/FinansalYonetim/GiderYonetimi/Giderler'),
            //   name: 'Giderler',
            //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gider Yönetimi - Görüntüleme"] }
            // },

            //Kira Gelirleri Sekmeleri
            ...withPrefix('/kira-gelirleri', [

              // Kira Gelirleri Dashboard
              {
                path: '',
                component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/KiraGelirleriDashboard'),
                name: 'KiraGelirleriDashboard',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Görüntüleme"] }
              },
              {
                path: '/tahakkuklar',
                component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/KiraTahakkuklariSekme.vue'),
                name: 'KiraTahakkuklariSekme',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/mahsuplar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/MahsuplarSekme'),
                name: 'MahsuplarSekme',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Mahsuplar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/faturalar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/FaturalarSekme'),
                name: 'FaturalarSekme',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Faturalar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/fatura-detay/:FaturaId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/AcikFaturaDetay'),
                name: 'AcikFaturaDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Faturalar - Görüntüleme"] }
              },
              {
                path: '/cari-hesaplar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/Tahsilat/TahsilatSekmeleri'),
                name: 'TahsilatSekmeleri',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Faturalar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/musteri-borc-bilgisi/:FirmaId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/Tahsilat/MusteriBorcDetay'),
                name: 'MusteriBorcDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              // Kira Gelirleri > Tahakkuk Detay
              {
                path: '/tahakkuk-detay/:TahakkukId',
                component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/TahakkukDetay.vue'),
                name: 'TahakkukDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/birlesmis-tahakkuk-detay/:TahakkukId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/BirlesmisTahakkukDetay'),
                name: 'BirlesmisTahakkukDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Kira Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
            ]),

            // Ortak Alan Katılım Gelirleri Sekmeleri
            ...withPrefix('/ortak-alan-katilim-gelirleri', [
            // {
            //   path: '/ortak-alan-katilim-gelirleri',
            //   component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/OrtakAlanGelirleri/OrtakAlanKatilimGelirleriSekme'),
            //   name: 'OrtakAlanKatilimGelirleriSekme',
            //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Görüntüleme"] }
            //   // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
            // },

              // Ortak Alan Katılım  Gelirleri Dashboard
              {
                path: '',
                component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/OrtakAlanGelirleri/OrtakAlanGelirleriDashboard'),
                name: 'OrtakAlanGelirleriDashboard',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Görüntüleme"] }
              },

              {
                path: '/tahakkuklar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/OrtakAlanGelirleri/Tahakkuklar/TahakkuklarSekme'),
                name: 'OrtakAlanGelirleri_TahakkuklarSekme',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Ortak Alan Katılım Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/mahsuplar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/OrtakAlanGelirleri/Mahsuplar/MahsuplarSekme'),
                name: 'OrtakAlanGelirleri_MahsuplarSekme',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Ortak Alan Katılım Gelirleri - Mahsuplar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/faturalar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/OrtakAlanGelirleri/Faturalar/FaturalarSekme'),
                name: 'OrtakAlanGelirleri_FaturalarSekme',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Ortak Alan Katılım Gelirleri - Faturalar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },

              // Kira Gelirleri > Tahakkuk Detay
              {
                path: '/tahakkuk-detay/:TahakkukId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/OrtakAlanGelirleri/Tahakkuklar/TahakkukDetay'),
                name: 'OrtakAlanGelirleri_TahakkukDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Ortak Alan Katılım Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
            ]),

            // Reklam Katılım Gelirleri Sekmeleri
            ...withPrefix('/reklam-katilim-gelirleri', [

              // Reklam Katılım  Gelirleri Dashboard
              {
                path: '',
                component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/ReklamGelirleri/ReklamGelirleriDashboard'),
                name: 'ReklamGelirleriDashboard',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Görüntüleme"] }
              },

              {
                path: '/tahakkuklar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/ReklamGelirleri/Tahakkuklar/TahakkuklarSekme'),
                name: 'ReklamGelirleri_TahakkuklarSekme',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Reklam Katılım Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/mahsuplar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/ReklamGelirleri/Mahsuplar/MahsuplarSekme'),
                name: 'ReklamGelirleri_MahsuplarSekme',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Reklam Katılım Gelirleri - Mahsuplar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/faturalar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/ReklamGelirleri/Faturalar/FaturalarSekme'),
                name: 'ReklamGelirleri_FaturalarSekme',
                preventThisPageForSeen: true,
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Reklam Katılım Gelirleri - Faturalar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },

              // Kira Gelirleri > Tahakkuk Detay
              {
                path: '/tahakkuk-detay/:TahakkukId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/ReklamGelirleri/Tahakkuklar/TahakkukDetay'),
                name: 'ReklamGelirleri_TahakkukDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Reklam Katılım Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
            ]),

            //Tüketim Gelirleri Sekmeleri
            ...withPrefix('/tuketim-gelirleri', [

              // // Tüketim Gelirleri Dashboard  /// DIGERLERINDE VAR AMA KULLANILMIYOR
              // {
              //   path: '',
              //   component: () => import('pages/PropertyRelated/FinansalYonetim/GelirYonetimi/KiraGelirleri/KiraGelirleriDashboard'),
              //   name: 'TuketimGelirleriDashboard',
              //   meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Görüntüleme"] }
              // },
              {
                path: '/tahakkuklar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/TuketimGelirleri/TuketimTahakkuklariSekme'),
                name: 'TuketimGelirleri_TahakkuklarSekme',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Tüketim Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },

              // Tüketim Gelirleri > Tahakkuk Detay
              {
                path: '/tahakkuk-detay/:TahakkukId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/TuketimGelirleri/TahakkukDetay'),
                name: 'TuketimGelirleri_TahakkukDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Tüketim Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },

            ]),
            ...withPrefix('/diger-gelirler', [
              {
                path: '/tahakkuklar',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/DigerGelirler/DigerGelirTahakkuklariSekme.vue'),
                name: 'DigerGelirler_TahakkuklarSekme',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Tüketim Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
              {
                path: '/tahakkuk-detay/:TahakkukId',
                component: () => import('src/pages/PropertyRelated/FinansalYonetim/GelirYonetimi/DigerGelirler/DigerGelirTahakkukDetay.vue'),
                name: 'DigerGelirler_TahakkukDetay',
                meta: { requiresAuth: true, hasAtLeastOneOf: ["Finansal Yönetim - Gelir Yönetimi - Tüketim Gelirleri - Tahakkuklar - Görüntüleme"] }
                // TODO: yeni yetkiler eklenirse duzenlenmeli , henuz eklenmedi
              },
            ]),
          ]),

          //Bütçe Yönetimi
          ...withPrefix('/butce-yonetimi', [
            // Bütçe Yonetimi Dashboard

          ]),
        ]),

        // Operasyon Yönetimi - Subpaths
        ...withPrefix('/alanlar', [

          // Tanımlamalar - Subpaths
          ...withPrefix('/tanimlamalar', [

            // Dashboard
            {
              path: '',
              name: 'AlanTanimlamalari',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Alanlar/AlanTanimlamalari/AlanTanimlamalari_Dashboard'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Alan Tanımlamaları - Görüntüleme"] },
            },

            // Katlar
            { path: '/katlar',
              name: 'Katlar',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Alanlar/AlanTanimlamalari/Katlar/KatPlani'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Alan Tanımlamaları - Görüntüleme"] },
            },

            // Yaya Girişleri
            { path: '/yaya-girisleri',
              name: 'YayaGirisleri',
              component: () => import('pages/PropertyRelated/Alanlar/AlanTanimlamalari/YayaGirisi/Kapilar'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Alan Tanımlamaları - Görüntüleme"] },
            },

            // Araç Girişleri
            { path: '/arac-girisleri',
              name: 'AracGirisleri',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Alanlar/AlanTanimlamalari/AracGirisi/AracKapilari'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Alan Tanımlamaları - Görüntüleme"] },
            },

            // Mal Kabul Girişleri
            { path: '/mal-kabul-girisleri',
              name: 'MalKabulGirisleri',
              component: () => import('pages/PropertyRelated/Alanlar/AlanTanimlamalari/MalKabulGirisi/MalKabulKapilari'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Alan Tanımlamaları - Görüntüleme"] },
            },

          ]),

          // Bağımsız Birimler - Subpaths
          ...withPrefix('/bagimsiz-birimler', [

            // Bağımsız Birimler
            {
              path: '',
              name: 'BagimsizBirimler',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Alanlar/BagimsizBirimler/BagimsizBirimler'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Bağımsız Birimler - Görüntüleme"] },
            },

            // Bağımsız Birim - Detay
            {
              path: '/:MahalId',
              name: 'BagimsizBirimDetay',
              component: () => import('pages/PropertyRelated/Alanlar/BagimsizBirimler/BagimsizBirimDetay'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Bağımsız Birimler - Görüntüleme"] },
            }

          ]),

          // Ortak Alanlar - Subpaths
          ...withPrefix('/ortak-alanlar', [

            // Ortak Alanlar
            {
              path: '',
              name: 'OrtakAlanlar',
              preventThisPageForSeen: true,
              component: () => import('pages/PropertyRelated/Alanlar/OrtakAlanlar/OrtakAlanlar'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Ortak Alanlar - Görüntüleme"] },
            },

            // Ortak Alan - Detay
            {
              path: '/:MahalId',
              name: 'OrtakAlanDetay',
              component: () => import('pages/PropertyRelated/Alanlar/OrtakAlanlar/OrtakAlanDetay'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Ortak Alanlar - Görüntüleme"] },
            }

          ]),
          // Kiralanabilir Bölümler - Subpaths
          ...withPrefix('/kiralanabilir-bolumler', [

            // Kiralanabilir Bölümler
            {
              path: '',
              name: 'KiralanabilirBolumler',
              component: () => import('pages/PropertyRelated/Alanlar/KiralanabilirBolumler/KiralanabilirBolumler'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Ortak Alanlar - Görüntüleme"] },
            },

            // Kiralanabilir Bölüm - Detay
            /*{
              path: '/:KiralanabilirBolumId',
              name: 'KiralanabilirBolumDetay',
              component: () => import('pages/PropertyRelated/Alanlar/KiralanabilirBolumler/KiralanabilirBolumDetay'),
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Alanlar - Ortak Alanlar - Görüntüleme"] },
            }*/

          ])

        ]),


        // Firmalar - Subpaths
        ...withPrefix('/firmalar', [

          // Dashboard
          {
            path: '/',
            component: () => import('pages/PropertyRelated/Firmalar/Firma Listesi/FirmaListesi_Dashboard'),
            name: 'firmaListesi',
            meta: { requiresAuth: true, hasAtLeastOneOf: [
                "Firmalar - Firma Listesi - Firma Ekleme Talepleri - Görüntüleme",
                "Firmalar - Firma Listesi - EPPSO Firmaları - Görüntüleme"]
            }
          },


          // Firma Listesi - Subpaths
          ...withPrefix('/firma-listesi', [

            // Firma Listesi
            {
              path: '/',
              component: () => import ('pages/PropertyRelated/Firmalar/Firma Listesi/EPPSOFirmalari/EPPSO_Firmalari'),
              name: 'EPPSO_Firmalari',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Firmalar - Firma Listesi - EPPSO Firmaları - Görüntüleme"] }
            },

            // Firma Listesi
            {
              path: '/:firmaId',
              component: () => import ('pages/PropertyRelated/Firmalar/Firma Listesi/EPPSOFirmalari/AvmFirmaDetay'),
              name: 'AvmFirmaDetay',
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Firmalar - Firma Listesi - EPPSO Firmaları - Görüntüleme"] }
            },


          ]),



          // Marka Ekleme Talepleri - Subpaths
          ...withPrefix('/marka-ekleme-talepleri', [

            // Marka Ekleme Talepleri Listesi
            {
              path: '',
              component: () => import ('pages/PropertyRelated/Firmalar/Firma Listesi/MarkaYaratmaTalepleri/MarkaYaratmaTalepleriListesi'),
              name: 'MarkaYaratmaTalepleri',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: ["Firmalar - Firma Listesi - Firma Ekleme Talepleri - Görüntüleme"] }
            },

          ])

        ]),

        // Raporlama - Subpaths
        ...withPrefix('/raporlama',[

          ...withPrefix('/idari-raporlar',[
            // İdari raporlar Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/Raporlama/IdariRaporlar/IdariRaporlar_Dashboard'),
              name: 'idariRaporlar',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - İdari Raporlar - Görüntüleme"
                ]
              }
            },
            {
              path: '/talep-ve-is-emri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/IdariRaporlar/TalepVeIsEmriRaporlari'),
              name: 'TalepVeIsEmriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                //TODO : idari Raporlarin altina gerekirse yeni alt kirilimlar eklenecek
                  //"Raporlama - İdari Raporlar - Talep Ve İş Emri - Görüntüleme", gibi
                  "Raporlama - İdari Raporlar - Görüntüleme",
                ]
              }
            },
            // {
            //   path: '/raporlardeneme',
            //   component: () => import('pages/PropertyRelated/Raporlama/IdariRaporlar/RaporlarFirst'),
            //   name: 'RaporlarDeneme',
            // },
            {
              path: '/guvenlik-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/IdariRaporlar/GuvenlikRaporlari'),
              name: 'GuvenlikRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  //TODO : idari Raporlarin altina gerekirse yeni alt kirilimlar eklenecek
                  //"Raporlama - İdari Raporlar - Talep Ve İş Emri - Görüntüleme", gibi
                  "Raporlama - İdari Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/personel-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/IdariRaporlar/PersonelRaporlari'),
              name: 'PersonelRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  //TODO : idari Raporlarin altina gerekirse yeni alt kirilimlar eklenecek
                  //"Raporlama - İdari Raporlar - Talep Ve İş Emri - Görüntüleme", gibi
                  "Raporlama - İdari Raporlar - Görüntüleme",
                ]
              }
            },

          ]),

          ...withPrefix('/verimlilik-raporlari',[
            // Verimlilik raporlar Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/VerimlilikRaporlari_Dashboard'),
              name: 'VerimlilikRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme"
                ]
              }
            },
            {
              path: '/ciro-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/CiroRaporlari.vue'),
              name: 'CiroRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },
            {
              path: '/karsilastirmali-ciro-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/KarsilastirmaliCiroRaporlari.vue'),
              name: 'KarsilastirmaliCiroRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },
            {
              path: '/ziyaretci-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/ZiyaretciRaporlari'),
              name: 'ZiyaretciRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  //TODO : idari Raporlarin altina gerekirse yeni alt kirilimlar eklenecek
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },
            {
              path: '/arac-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/AracRaporlari'),
              name: 'AracRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },
            {
              path: '/sinema-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/SinemaRaporlari'),
              name: 'SinemaRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },
            {
              path: '/eglence-alani-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/EglenceAlaniRaporlari'),
              name: 'EglenceAlaniRaporlari',
              preventThisPageForSeen: true,
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },

            {
              path: '/doluluk-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/DolulukRaporlari'),
              name: 'DolulukRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },

            {
              path: '/sektorel-dagilim-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/VerimlilikRaporlari/SektorelDagilimRaporlari'),
              name: 'SektorelDagilimRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Verimlilik Raporları - Görüntüleme",
                ]
              }
            },

          ]),

          ...withPrefix('/donemsel-raporlar',[
            // donemsel raporlar Dashboard
            {
              path: '',
              component: () => import('pages/PropertyRelated/Raporlama/DonemselRaporlar/DonemselRaporlar_Dashboard'),
              name: 'donemselRaporlar',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Dönemsel Raporlar - Görüntüleme"
                ]
              }
            },
            {
              path: '/gunaydin-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/DonemselRaporlar/GunaydinRaporlari'),
              name: 'GunaydinRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Dönemsel Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/aylik-raporlar',
              component: () => import('pages/PropertyRelated/Raporlama/DonemselRaporlar/AylikRaporlar'),
              // component: () => import('pages/YapimAsamasinda'),
              name: 'AylikRaporlar',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Dönemsel Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/yillik-raporlar',
              //component: () => import('pages/PropertyRelated/Raporlama/DonemselRaporlar/YillikRaporlar'),
              component: () => import('pages/YapimAsamasinda') ,
              name: 'YillikRaporlar',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Dönemsel Raporlar - Görüntüleme",
                ]
              }
            },


          ]),

          ...withPrefix('/finansal-raporlar',[
            {
              path: '',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/FinansalRaporlar_Dashboard'),
              name: 'FinansalRaporlar',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme"
                ]
              }
            },
            {
              path: '/kira-geliri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/KiraGeliriRaporlari'),
              name: 'KiraGeliriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/karsilastirmali-kira-geliri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/KarsilastirmaliKiraGeliriRaporlari'),
              name: 'KarsilastirmaliKiraGeliriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/ortak-alan-katilim-geliri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/OrtakAlanKatilimGeliriRaporlari'),
              //component: () => import('pages/YapimAsamasinda') ,
              name: 'OrtakAlanKatilimGeliriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/karsilastirmali-ortak-alan-katilim-geliri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/KarsilastirmaliOrtakAlanKatilimGeliriRaporlari'),
              name: 'KarsilastirmaliOrtakAlanKatilimGeliriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/reklam-katilim-geliri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/ReklamKatilimGeliriRaporlari'),
              //component: () => import('pages/YapimAsamasinda') ,
              name: 'ReklamKatilimGeliriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/karsilastirmali-reklam-katilim-geliri-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/KarsilastirmaliReklamKatilimGeliriRaporlari'),
              name: 'KarsilastirmaliReklamKatilimGeliriRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/kira-oak-rk-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/KiraOAKRKRaporlari.vue'),
              name: 'KiraOAKRKRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },
            {
              path: '/karsilastirmali-kira-oak-rk-raporlari',
              component: () => import('pages/PropertyRelated/Raporlama/FinansalRaporlar/KarsilastirmaliKiraOAKRKRaporlari'),
              name: 'KarsilastirmaliKiraOAKRKRaporlari',
              meta: { requiresAuth: true, hasAtLeastOneOf: [
                  "Raporlama - Finansal Raporlar - Görüntüleme",
                ]
              }
            },

          ]),

        ])


      ]),

      // TODO: MALL LEFTOVERS
      {
        path: '/securityCheckQr',
        component: () => import('pages/PropertyRelated/KareKod/GuvenlikQrRead'),
        name: 'securityCheckQr',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini","Operasyon Yönetimi - Güvenlik Yönetimi - QR Kod Okuma"] }
      },
      {
        path: '/securityCheckQrTEST',
        component: () => import('pages/PropertyRelated/KareKod/html5qrCodeTEST'),
        name: 'securityCheckQrTEST',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: '/QrGirisCikis/:enterOrExit',
        component: () => import('pages/PropertyRelated/KareKod/QrGirisCikislar'),
        name: 'QrGirisCikis',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: '/tedarikci_listesi',
        //component: () => import('pages/PropertyRelated/Tedarikciler/Pages/TedarikciListesi'),
        component: () => import('pages/YapimAsamasinda') ,
        name: 'tedarikciListesi',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: '/tedarikci_kaydet',
        //component: () => import('pages/PropertyRelated/Tedarikciler/Pages/tedarikci-create'),
        component: () => import('pages/YapimAsamasinda') ,
        name: 'tedarikciCreate',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },



      {
        path: '/u',
        name: 'u',
        preventThisPageForSeen: true,
        component: () => import('layouts/UserPanelLayout'),
        children: [
          { path: 'profilim',
            component: () => import('pages/UserRelated/UserProfile') ,
            name: 'UserProfile',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          { path: 'giris-karti',
            component: () => import('pages/UserRelated/eppsoGirisKarti'),
            name: 'eppsoGirisKarti',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          { path: 'sifre-degistir',
            component: () => import('pages/UserRelated/ChangePassword') ,
            name: 'ChangePassword',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          { path: 'bildirimler',
            component: () => import('pages/UserRelated/Bildirimler') ,
            name: 'Bildirimler',
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          { path: 'mesajlar',
            component: () => import('pages/UserRelated/Mesajlar') ,
            name: 'Mesajlar',
            preventThisPageForSeen: true,
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          { path: 'duyurular',
            component: () => import('pages/UserRelated/Duyurular') ,
            name: 'Duyurular',
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          { path: 'anket',
            component: () => import('pages/UserRelated/Anket/GelenAnketListesi') ,
            name: 'Anket',
            meta: { requiresAuth: true, hasAtLeastOneOf: [] }
          },
          ]
      },


      {
        path: '/myQr',
        component: () => import('pages/UserRelated/QrCodeInProfile'),
        name: 'myQr',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: '/avmQr',
        component: () => import('pages/UserRelated/QrCodeInAvm'),
        name: 'avmQr',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      { path: 'anketYanitla/:anketId',
        component: () => import('pages/UserRelated/Anket/AnketYanitla') ,
        name: 'anketYanitla',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      { path: 'anketDetay/:anketId',
        component: () => import('pages/UserRelated/Anket/AnketDetay') ,
        name: 'anketDetay',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },

      // TODO: EPPSO
      {
        path: '/tanimlamalar/sozlesme_basliklari',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/SozlesmeBasliklari') ,
        name: 'sozlesmeBasliklari',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/sozlesme_basliklari/:sozlesmeBaslikId/sozlesme_tipleri',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/SozlesmeTipleri') ,
        name: 'sozlesmeTipleri',
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/markalar',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/MarkalarSekme') ,
        name: 'markalar',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/Paketler',
        component: () => import('pages/EppsoRelated/Paketler/Paketler'),
        name: 'Paketler',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/markalar/marka-yaratma-talepleri',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/MarkaYaratmaTalepListesi'),
        name: 'MarkaYaratmaTalepAdmin',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/ulkeler',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Ulkeler') ,
        name: 'ulkeler',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/ulkeler/:ulkeID/sehirler',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Sehirler') ,
        name: 'sehirler',
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/ulkeler/:ulkeID/sehirler/:sehirID/ilceler',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Ilceler') ,
        name: 'ilceler',
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/olcuBirimleri',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/OlcuBirimleri'),
        name: 'olcuBirimleri',
        preventThisPageForSeen: true,
        meta: {requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"]}
      },
      {
        path: '/tanimlamalar/kullanicilar',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Kullanicilar') ,
        name: 'kullanicilar',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/yetkiler',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Yetkiler'),
        name: 'yetkiler',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/departmanlar',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Departmanlar'),
        name: 'adminDepartmanlar',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/tanimlamalar/pozisyonlar',
        component: () => import('pages/EppsoRelated/Tanimlamalar/Pages/Pozisyonlar'),
        name: 'adminPozisyonlar',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: 'insanKaynaklari/egitimTipleri',
        component: () => import('pages/EppsoRelated/InsanKaynaklari/EgitimTipleri') ,
        name: 'egitimTipleri',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/insanKaynaklari/calismaDuzenleri',
        component: () => import('pages/EppsoRelated/InsanKaynaklari/CalismaDuzenleri'),
        name: 'calismaDuzenleri',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/IK/eppso-personel-listesi',
        component: () => import('pages/EppsoRelated/InsanKaynaklari/Guncel/EPPSO_PersonelListesi'),
        name: 'EPPSO_PersonelListesi',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/CMS/Galeri',
        component: () => import('pages/EppsoRelated/CMS/Galeri/IcerikYonetimi'),
        name: 'IcerikYonetimi',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: '/CRM/Firmalar',
        component: () => import('pages/EppsoRelated/CRM/FirmalarSekme'),
        name: 'firmalar',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },
      {
        path: 'c/:firmaId',
        component: () => import('layouts/FirmaPanelLayout'),
        children: [
          {
            path: 'firma-profili',
            component: () => import('pages/EppsoRelated/CRM/firma-profili'),
            name: 'FirmaProfili',
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
          },
          { path: 'firma-personelleri',
            component: () => import('pages/EppsoRelated/CRM/firma-profili'),
            name: 'FirmaPersonelleri',
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
          },
          { path: 'personel-yetkileri',
            component: () => import('pages/EppsoRelated/CRM/firma-profili'),
            name: 'FirmaPersonelYetkileri',
            meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
          },
        ]
      },

      {
        path: '/Finans/finans-gider-listeleri',
        component: () => import('pages/EppsoRelated/Finans/Gider/Finans_GiderListeleriSekme'),
        name: 'Finans_GiderListeleriSekme',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: ["Sistem Admini"] }
      },

      // TODO: FİRMA

      // TODO: Silinecek mi?

      {
        path: 'TalepDetay/:TalepId',
        component: () => import('pages/PropertyRelated/OperasyonYonetimi/IsEmriVeTalepYonetimi/Taleplerim/TalepDetay') ,
        name: 'TalepDetay',
        meta: { requiresAuth: true, hasAtLeastOneOf: [
            "Operasyon Yönetimi - İş Emri ve Talep Yönetimi - Taleplerim - Görüntüleme", "Mağazam - Talep İşlemleri",
          ] }
      },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'YapimAsamasinda',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'Tedarikciler',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'BağımsızBirimDevirleri',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'FinansalYonetim',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'TahsilatYonetimi',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'BütceYönetimi',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      /*{
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'idariRaporlar',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },*/

      /*{
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'VerimlilikRaporlari',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },*/

      /*{
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'ZiyaretciRaporlari',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },*/

      /*{
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'FinansalRaporlar',
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }

      },
      // {
      //   path: 'YapimAsamasinda',
      //   component: () => import('pages/YapimAsamasinda') ,
      //   name: 'GelirYonetimi',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },
      } */

      // {
      //   path: 'YapimAsamasinda',
      //   component: () => import('pages/YapimAsamasinda') ,
      //   name: 'GelirYonetimi',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },
      // {
      //   path: 'YapimAsamasinda',
      //   component: () => import('pages/YapimAsamasinda') ,
      //   name: 'GiderYonetimi',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },
      // {//TODO: Sayfa tamamlandiginda kaldirilacak
      //   path: 'YapimAsamasinda',
      //   component: () => import('pages/YapimAsamasinda') ,
      //   name: 'Giderler',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },
      // {//TODO: Sayfa tamamlandiginda kaldirilacak
      //   path: 'YapimAsamasinda',
      //   component: () => import('pages/YapimAsamasinda') ,
      //   name: 'AvmGiderTipleri',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },

      // {
      //   path: 'YapimAsamasinda',
      //   component: () => import('pages/YapimAsamasinda') ,
      //   name: 'm2BirimFiyatListesi',
      //   meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      // },
      {
        path: 'YapimAsamasinda',
        component: () => import('pages/YapimAsamasinda') ,
        name: 'Tahakkuk',
        preventThisPageForSeen: true,
        meta: { requiresAuth: true, hasAtLeastOneOf: [] }
      },
      {
        path: 'YetkinizYok_403',
        component: () => import('pages/Error403.vue') ,
        name: 'Error403',
        preventThisPageForSeen: true,
      },



    ]
  },

  {
    path: '/teslimFormu/:teslimId',
    component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/teslimBelgesi') ,
    name: 'teslimBelgesi',
  },
  {
    path: '/teslimAlmaFormu/:teslimId',
    component: () => import('pages/PropertyRelated/KiraciYonetimi/KiraciListesi/teslimAlmaBelgesi') ,
    name: 'teslimAlmaBelgesi',
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    name: 'NotFound',
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]



export default routes
