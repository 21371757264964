// default src/store/index.js content:
import { createStore } from 'vuex'
// import example from './module-example'

export default function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      // example
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,

    state() {

      return {
        totalMessageNotification: 0, // Varsayılan mesaj bildirimi sayısı
        totalAnnouncementNotification: 0 // Varsayılan duyuru bildirimi sayısı
      };
    },
    mutations: {
      //Mesajlar
      setMessageNotification(state, newTotalMessageNotification) {
        state.totalMessageNotification = newTotalMessageNotification;
      },
      messageNotificationIncrement(state) {
        state.totalMessageNotification++;
      },
      messageNotificationDecrement(state) {
        state.totalMessageNotification--;
      },

      //Duyurular
      setAnnouncementNotification(state, newTotalAnnouncementNotification) {
        state.totalAnnouncementNotification = newTotalAnnouncementNotification;
      },
      announcementNotificationIncrement(state) {
        state.totalAnnouncementNotification++;
      },
      announcementNotificationDecrement(state) {
        state.totalAnnouncementNotification--;
      },

    },
    actions: {
      //Mesajlar
      changeMessageNotification({ commit }, newTotalMessageNotification) {
        commit('setMessageNotification', newTotalMessageNotification);
      },
      messageNotificationIncrement({ commit }) {
        commit('messageNotificationIncrement');
      },
      messageNotificationDecrement({ commit }) {
        commit('messageNotificationDecrement');
      },

      //Duyurular
      changeAnnouncementNotification({ commit }, newTotalAnnouncementNotification) {
        commit('setAnnouncementNotification', newTotalAnnouncementNotification);
      },
      announcementNotificationIncrement({ commit }) {
        commit('announcementNotificationIncrement');
      },
      announcementNotificationDecrement({ commit }) {
        commit('announcementNotificationDecrement');
      }

    }

  })

  return Store
}


