import requiredR from "src/infrastructure/validation_related/rules/requiredR";
import {cloneDeep} from "lodash";

const {validationFunc} = requiredR();

function filterIt(mainFilterObject, requestObject) {
  let firstPart = "";
  let secondPart = "";
  let separationOperator = "";
  let MonoEnumFilter = [];
  let MultiEnumFilter = [];
  let MonoStringEnumFilter = [];
  let MultiStringEnumFilter = [];

  for (let key of Object.keys(mainFilterObject))
  {
    if(validationFunc(mainFilterObject[key].val))
    {
      if(mainFilterObject[key].hasOwnProperty("separateOperator"))
      {
        separationOperator = mainFilterObject[key].separateOperator;

        for(let field of mainFilterObject[key]["fields"])
        {
          secondPart += field + mainFilterObject[key].operator + mainFilterObject[key].val + ";";
        }

        secondPart += "or";
      }
      else if(mainFilterObject[key].hasOwnProperty("type"))
      {
        if(mainFilterObject[key].type === filterTypeEnums.Enum){
          MonoEnumFilter.push({
            IsEqual: mainFilterObject[key].operator,
            FieldName: mainFilterObject[key]["fields"][0],
            FieldValue: mainFilterObject[key].val
          });
        }else if(mainFilterObject[key].type === filterTypeEnums.MultiEnum){
          MultiEnumFilter.push({
            FieldName: mainFilterObject[key]["fields"][0],
            FieldValue: mainFilterObject[key].val
          });
        }

        if(mainFilterObject[key].type === filterTypeEnums.StringEnum){
          MonoStringEnumFilter.push({
            IsEqual: mainFilterObject[key].operator,
            FieldName: mainFilterObject[key]["fields"][0],
            StringFieldValue: mainFilterObject[key].label
          });
        }else if(mainFilterObject[key].type === filterTypeEnums.MultiStringEnum){
          MultiStringEnumFilter.push({
            FieldName: mainFilterObject[key]["fields"][0],
            StringFieldValue: mainFilterObject[key].label
          });
        }

      }
      else
      {
        for(let field of mainFilterObject[key]["fields"])
        {
          firstPart += field + mainFilterObject[key].operator + mainFilterObject[key].val + ";";
        }
      }
    }
  }

  if(firstPart)
  {
    firstPart += "and";
  }

  if(firstPart && secondPart)
  {
    secondPart = separationOperator + secondPart;
  }

  requestObject.Filter = firstPart + secondPart;
  requestObject.MonoEnumFilter = MonoEnumFilter;
  requestObject.MultiEnumFilter = MultiEnumFilter;
  requestObject.MonoStringEnumFilter = MonoStringEnumFilter;
  requestObject.MultiStringEnumFilter = MultiStringEnumFilter;
}

/**
 * GENERAL PURPOSE
 * ---------------------
 *
 *
 * PARAMETERS
 * ---------------------
 * @baseObject:
 * @currentPaginationParams:
 * @filterData:
 * @requestObject:
 **/
function paginateIt(baseObject, currentPaginationParams, filterData, requestObject) {
  /* Extract values from pagination object (see Q-Table > props > pagination)
   * page:
   * rowsPerPage: How many rows to display in a single 'page'
   * sortBy: Which column to sort the table by
   * descending: whether the sorting will be in descending order or not */
  const { page, rowsPerPage, sortBy, descending } = currentPaginationParams;

  // Add page number and rowsPerPage values to request parameters
  requestObject.PageNumber= page;
  requestObject.PageSize= rowsPerPage;

  // Add filter values to request parameters
  filterIt(filterData, requestObject);

  if(sortBy)
  { requestObject.Order = descending ? sortBy+";desc" : sortBy+";" }

  // Update caller component's base object to update displayed pagination values on screen
  baseObject.pagination.page = page;
  baseObject.pagination.rowsPerPage = rowsPerPage;
  baseObject.pagination.sortBy = sortBy;
  baseObject.pagination.descending = descending;
}

function numericOptions(filterFunc = undefined) {
  let options = [
    {
      operator: "==",
      str: "Eşittir"
    },
    {
      operator: "!=",
      str: "Eşit Değildir"
    },
    {
      operator: ">",
      str: "Büyüktür"
    },
    {
      operator: ">=",
      str: "Büyük veya eşittir"
    },
    {
      operator: "<",
      str: "Küçüktür"
    },
    {
      operator: "<=",
      str: "Küçük veya eşittir"
    }
  ];

  if(!filterFunc){
    return options.filter(filterFunc);
  }else{
    return options;
  }
}

function strOptions(filterFunc = undefined){
  let options = [
    {
      operator: "~=",
      str: "içeren"
    },
    {
      operator: "_=",
      str: "ile başlayan"
    },
    {
      operator: "|=",
      str: "ile biten"
    },
    {
      operator: "!~=",
      str: "içermeyen"
    },
    {
      operator: "!_=",
      str: "ile başlamayan"
    },
    {
      operator: "!|=",
      str: "ile bitmeyen"
    },
    {
      operator: "==",
      str:"eşitir"
    }
  ];

  if(!filterFunc){
    return options.filter(filterFunc);
  }else{
    return options;
  }
}

function enumOptions(filterFunc = undefined){
  let options = [
    {
      operator: true,
      str: "Eşittir"
    },
    {
      operator: false,
      str: "Eşit Değildir"
    }
  ];

  if(!filterFunc){
    return options.filter(filterFunc);
  }else{
    return options;
  }
}

function updateFilter(tableData){
  //beforeFilter ve tableFilter aynı obje referansını kullanmamalı
  const clone_of_beforeFilter = cloneDeep(tableData);
  for (let key of Object.keys(tableData.beforeFilter)){
    tableData.tableFilter[key].val = clone_of_beforeFilter.beforeFilter[key].val;
    tableData.tableFilter[key].operator = clone_of_beforeFilter.beforeFilter[key].operator;
    tableData.isFiltered = true;
  }
}

function clearFilter(tableData){
  const kontrolObject = requiredR();
  for (let key of Object.keys(tableData.beforeFilter)){
    if(kontrolObject.validationFunc(tableData.beforeFilter[key].val)){
      if(tableData.beforeFilter[key].type === filterTypeEnums.MultiEnum){
        tableData.beforeFilter[key].val = [];
        tableData.tableFilter[key].val = [];
      }else{
        tableData.beforeFilter[key].val = "";
        tableData.tableFilter[key].val = "";
      }
    }
  }
  tableData.isFiltered = false;
}

const filterTypeEnums = Object.freeze({
  "Num": "Number",
  "Str": "String",
  "Date": "DateTime",
  "Enum": "Enum",
  "MultiEnum": "MultiEnum",
  "StringEnum": "StringEnum",
  "MultiStringEnum" : "MultiStringEnum"
});

const dateRenderOptionEnums = Object.freeze({
  "R": "Render",
  "NR": "NoRender",
  "HR": "HalfRender",
  "HRWA": "HalfRenderWithAdjacent"
});

export {
  paginateIt,
  numericOptions,
  strOptions,
  enumOptions,
  updateFilter,
  clearFilter,
  filterTypeEnums,
  filterIt,
  dateRenderOptionEnums
}
