import token_solver from "src/infrastructure/functions/token_solver";
import {isEmpty} from "lodash";
import {date, Loading, useQuasar} from "quasar";
import gr_axios from "src/infrastructure/request_base/gr_axios";
import fileDownload from "src/infrastructure/request_base/fileDownload";
import * as pako from "pako/dist/pako.esm.mjs";
import jsPDF from "jspdf";

export const $fns = {

  getSozluk:async ()=>{
    const getSozluk = gr_axios('GET', 'Sozluk/GetSozluk');
    await getSozluk.getResponse();

    localStorage.setItem("Ceviri", JSON.stringify(getSozluk.response.value));
  },
  isLoggedIn: () => {
    let token = localStorage.getItem(process.env.ACCESS_TOKEN);

    if (!token) return false;

    if($fns.compareDates( new Date(), $fns.getSessionExpirationDate() ) > 0){
      localStorage.removeItem(process.env.ACCESS_TOKEN);
      return false;
    }


    return true;
  },

  hasClaim: (claim) => {
    let defaultErrorMessage = 'hasClaim işlemi sırasında hata oluştu.';
    try{

      let token = token_solver(localStorage.getItem(process.env.ACCESS_TOKEN));

      if(typeof(claim) !== 'string'){
        throw new Error('hasClaim fonksiyonuna sağlanan claim parametresi string tipinde olmalıdır!')
      }

      let inToken = token.hasOwnProperty(claim);

      if(inToken)
        return true;

      let activeKiraciId = token['ActiveKiraciId'];
      if(token['MagazaRelated']!==undefined){
        let yetkiliOlunanMagazalar = JSON.parse(token['MagazaRelated']);

        if(yetkiliOlunanMagazalar[activeKiraciId] === undefined)
          return false;

        return yetkiliOlunanMagazalar[activeKiraciId]['Claims'].includes(claim);
      }

    }
    catch(e){
      console.error(e === undefined ? new Error(defaultErrorMessage) : e);
      return false;
    }
  },

  hasAtLeastOneOf: (claimList) => {
    let defaultErrorMessage = 'hasAtLeastOneOf işlemi sırasında hata oluştu.';

    try{

      if(Array.isArray(claimList) === false){
        throw new Error('hasNoneOf fonksiyonuna sağlanan parametre array tipinde olmalıdır!')
      }

      if(isEmpty(claimList)) return true;

      for(let i = 0; i < claimList.length; i++){
        if( $fns.hasClaim(claimList[i]) ) return true;
      }

      return false;
    }
    catch(e){
      console.error(e === undefined ? new Error(defaultErrorMessage) : e);
      return false;
    }
  },

  hasAllOf: (claimList) => {
    let defaultErrorMessage = 'hasAllOf işlemi sırasında hata oluştu.';
    try{
      if(Array.isArray(claimList) === false){
        throw new Error('hasAllOf fonksiyonuna sağlanan parametre array tipinde olmalıdır!')
      }
      for(let i = 0; i < claimList.length; i++){
        if( $fns.hasClaim(claimList[i]) === false ) return false;
      }
      return true;
    }
    catch(e){
      console.error(e === undefined ? new Error(defaultErrorMessage) : e);
      return false;
    }
  },

  hasNoneOf: (claimList) => {
    let defaultErrorMessage = 'hasNoneOf işlemi sırasında hata oluştu.';
    try{
      if(Array.isArray(claimList) === false){
        throw new Error('hasNoneOf fonksiyonuna sağlanan parametre array tipinde olmalıdır!')
      }
      for(let i = 0; i < claimList.length; i++){
        if( $fns.hasClaim(claimList[i]) ) return false;
      }
      return true;
    }
    catch(e){
      console.error(e === undefined ? new Error(defaultErrorMessage) : e);
      return false;
    }
  },

  getSessionExpirationDate: () => {
    let accessTokenObject = token_solver(localStorage.getItem(process.env.ACCESS_TOKEN));
    return $fns.convertUnixToDateTime(accessTokenObject["exp"]);
  },

  convertUnixToDateTime:(UnixTimestamp) => {
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    let date;
    date = new Date(UnixTimestamp * 1000);
    return date;
  },

  compareDates: (date1,date2) => {
    return date1 > date2 ? 1 : -1;
  },

  parseFloatForLocale:(val,maxDigits=2)=> {

    if(isNaN(parseFloat(val)))
      return;
    else{
      return parseFloat(val).toLocaleString
      (
        'tr-TR',
        {minimumFractionDigits:2,maximumFractionDigits:maxDigits}
      );
    }
  },
  localTimeToUtc:(date)=>{
    let utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return utcDate;
  },
  convertToBackendDateTime: (date, time) => {
    try{
      if(isEmpty(date))
        throw new Error("Date argument should be provided.");

      let day = date.split('-')[2];
      let month = date.split('-')[1];
      let year = date.split('-')[0];

      let dateWithTime = year + "/" + month + "/" + day;

      if(isEmpty(time) === false){
        let hours = time.split(':')[0];
        let minutes = time.split(':')[1];

        dateWithTime += " " + hours + ':' + minutes;
      }

      return dateWithTime;
    }
    catch(e){
      console.error(e.message);
    }
  },
  isNotEmpty: (val) => {
    return !isEmpty(val);
  },

  convertToQuasarDateTime: (backendDate, format='YYYY-MM-DD' ) => {
    try{
      if(isEmpty(backendDate))
        throw new Error("Date argument should be provided.");

      return date.formatDate(new Date(backendDate),format)
    }
    catch(e){
      console.error(e.message);
    }
  },
  downloadFileBase64:(file,filename)=>{
  const linkSource = file;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = filename+file.split(';')[0].split('/')[1];
  downloadLink.click();
},
  maskString:(str,start=2,end=2) => {
    if(isEmpty(str)) return null;
  const maskedLength = str.length - start+end;
  return str.slice(0, start) + '*'.repeat(maskedLength) + str.slice(-end);
},
  downloadFile:(file)=>{
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)

    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
},
  triggerFileDownload: async (fileId) => {
    let getFileRequest= gr_axios("GET","File/GetFile?id="+fileId, null, 'application/json', 'blob');
    await getFileRequest.getResponse();
    fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
  },

  triggerFileOpenInNewTab: async (fileId) => {
    let getFileRequest= gr_axios("GET","File/GetFile?id="+fileId, null, 'application/json', 'blob');//arraybuffer
    await getFileRequest.getResponse();
    // console.log(getFileRequest.response.value)

    try {
      // console.log('Response data:', getFileRequest.response.value);
      // console.log('Response data:', getFileRequest.response.value.type);

      const mimeType = getFileRequest.response.value.type  // image/jpeg  or application/pdf or else?

      // const pdfBlob = new Blob([getFileRequest.response.value], { type: 'application/pdf' });
      const fileBlob = new Blob([getFileRequest.response.value], { type: mimeType });
      // console.log('PDF Blob:', fileBlob);
      const fileUrl = URL.createObjectURL(fileBlob);

      // console.log('PDF URL:', fileUrl);
      window.open(fileUrl, '_blank'); // Open the URL in a new tab
    } catch (error) {
      console.error('Error fetching or opening file:', error);
    }

  },

  displayFileInNewTab: async (file) => {
    if (file) {
      // Create a Blob URL for the file object
      const fileUrl = URL.createObjectURL(new Blob([file], { type: file.type }));
      // Open the URL in a new tab
      window.open(fileUrl, '_blank');
    } else {
      console.error('No file object found');
    }

  },

  reportExcelDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null) => {
    const token = token_solver(localStorage.getItem(process.env.ACCESS_TOKEN));
    window.gtag('event', 'excel_button_press', {
      event_category: dosyaAdi + raporAdi + ' Excel',
      event_label: dosyaAdi + raporAdi + ' excel butonuna basıldı',
      user_properties: {
        kullanici_id: token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
        avm_id: token['ActiveAvmId']
      }
    });

    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }
    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelFromRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

  reportExcelDownloadWithMarkaDetails: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }
    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelFromRaporWithMarkaDetails?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

  nestedReportExcelDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, unit, filters) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }

    // Diziyi JSON formatına çevirme
    var jsonFilters = JSON.stringify(filters);

    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelFromNestedRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&unit="+unit+ "&filters="+jsonFilters, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },
  reportExcelMarkaVeSektorDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }
    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelMarkaVeSektorRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

  reportExcelMarkaVeKategoriDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }
    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelMarkaVeKategoriRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

  reportExcelMarkaVeKatDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }
    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelMarkaVeKatRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

  reportExcelMarkaVeMahalTipiDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }
    let getFileRequest= gr_axios("POST","Rapor/GenerateExcelMarkaVeMahalTipiRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

  nestedCompareReportExcelDownload: async (dosyaAdi, raporAdi,magazaId=null, chartData, yil= null,ay= null, compareYil= null, compareAy= null, unit, filters) => {
    Loading.show();
    if(magazaId === null){
      magazaId = '';
    }
    if(ay === null){
      ay = '';
    }
    if(yil === null){
      yil = '';
    }
    if(compareYil === null){
      compareYil = '';
    }
    if(compareAy === null){
      compareAy = '';
    }

    // Diziyi JSON formatına çevirme
    var jsonFilters = JSON.stringify(filters);

    let getFileRequest= gr_axios("POST","Rapor/GenerateCompareExcelFromNestedRaporChartModel?dosyaAdi=" + dosyaAdi+ "&raporAdi="+raporAdi+ "&magazaId="+magazaId+ "&yil="+yil+ "&ay="+ay+ "&compareYil="+compareYil+ "&compareAy="+compareAy+ "&unit="+unit+ "&filters="+jsonFilters, chartData, 'application/json', 'blob');
    await getFileRequest.getResponse();

    // Error/Success Handling
    const $q = useQuasar();
    if (isEmpty(getFileRequest.error.value)){
      fileDownload(getFileRequest.response.value, getFileRequest.responseHeaders.value);
      Loading.hide();
    }
    else{
      if (typeof getFileRequest.error.value["data"] === "string"){
        $q.notify({color:"red",textColor:"white", message: getFileRequest.error.value["data"], position:"top"})
      }else{
        $q.notify({color:"red",textColor:"white", message: t("bilinmeyenBirHataOlustuSistemYoneticinizeBasvurun"), position:"top"})
      }
    }

  },

   generateReportPDF : (charts, reportTitle, reportDate) => {
     console.log("generateReportPDF start")
    try {
      const chartArray = [];
      const titleArray = [];
      const filtersArray = [];

      Object.entries(charts).forEach(([label, value]) => {
        chartArray.push(value.chart);
        titleArray.push(value.title);
        filtersArray.push(value.filters);

      });

      // PDF oluşturma işlemleri
      const doc = new jsPDF();
      const title = reportTitle;
      console.log("generateReportPDF # document created")

      // Logo resmini HTML sayfasına ekleyin
      const logoImage = new Image(); //Logo açılacak
      //logoImage.src = '../img/beyaz-arkaplan-decoupe.png'; // Logo resminin yolunu belirtin: eppso: 70*50 //TODO: Logo açılacak
      logoImage.src = '../../assets/beyaz-arkaplan-decoupe.png'; // Logo resminin yolunu belirtin: eppso: 70*50
      console.log(logoImage);

      //logoImage.onload = function() { //Logo açılacak
      console.log("generateReportPDF # logoImage.onload çalıştı")
      const logoWidth = 28; // Logo genişliğini ayarlayın
      const logoHeight = 20; // Logo yüksekliğini ayarlayın

        const totalPages = Object.entries(charts).length; // Grafik sayısı kadar sayfa olacak
        let pageNumber = 1; // Sayfa numarası

        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i); // Sayfayı ayarla

        // Logo resmini her sayfanın başına ekle
        //doc.addImage(logoImage, 'PNG', 10, 10, logoWidth, logoHeight); //Logo açılacak
        console.log("generateReportPDF # logoImage")
        //console.log(logoImage)

        }
        doc.setFontSize(18);
        const titleWidth = doc.getTextWidth(title); // Başlık metninin genişliğini al
        const pageWidth = doc.internal.pageSize.getWidth(); // Sayfa genişliğini al
        const titleXPos = (pageWidth - titleWidth) / 2; // Başlığın ortalanmış yatay pozisyonunu hesapla
        doc.text(titleXPos, 20, title); // Başlığı ortalanmış pozisyonda yazdır

        // İndirilme tarihini ekleyin
        const downloadDate = new Date().toLocaleDateString('tr-TR');
        const downloadDateWidth = doc.getTextWidth(downloadDate);
        const downloadDateXPos = pageWidth - downloadDateWidth - 7;
        doc.setFontSize(10);
        doc.text(downloadDateXPos, 20, downloadDate);

        let yPos = 40; // Y başlangıç pozisyonu
        doc.setFontSize(12);
        const tarihFilter = 'Tarih: ' + reportDate; // Filtreleme durumu metni

        doc.text(10, yPos, tarihFilter);
        yPos += 10;

        chartArray.forEach((chart, index) => {
          if (index > 0) {
            doc.addPage(); // Her yeni grafik için yeni bir sayfa oluşturun
            yPos = 40; // Y başlangıç pozisyonunu sıfırlayın
          }
          //doc.addImage(logoImage, 'PNG', 10, 10, logoWidth, logoHeight); //Logo açılacak
          // Başlık yazdırma
          const charttitle = titleArray[index] || ''; // Grafik başlığı
          doc.setFontSize(18);

          const subtitleWidth = doc.getTextWidth(charttitle);
          const subtitleXPos = (pageWidth - subtitleWidth) / 2;

          doc.text(subtitleXPos, yPos, charttitle);
          yPos += 10; // Başlık yüksekliği ve boşluk için pozisyonu güncelleyin

          // Filtreleme durumu yazdırma
          let filters = filtersArray[index] || ''; // Filtreleme durumu metni
          if(filters !== ''){
            filters = 'Filtreler: '+filters;
          }
          doc.setFontSize(12);
          doc.text(10, yPos, filters);
          yPos += 10; // Filtreleme durumu yüksekliği ve boşluk için pozisyonu güncelleyin

          const canvas = chart.canvas;
          const imageData = canvas.toDataURL('image/png');
          const xPos = 10;
          const width = doc.internal.pageSize.getWidth() - 20; // Sayfa genişliği
          const height = (width * canvas.height) / canvas.width; // Orantılı bir yükseklik hesaplayın
          yPos += 10; // Grafik ve boşluk için pozisyonu güncelleyin
          doc.addImage(imageData, 'PNG', xPos, yPos, width, height);
          yPos += height; // Grafik yüksekliği ve boşluk için pozisyonu güncelleyin

          // Grafik verilerinin toplamını hesapla
          const data = chart.data.datasets[0].data;
          let total = data.reduce((acc, curr) => acc + curr, 0);
          total = total.toLocaleString('tr-TR',{minimumFractionDigits: 0, maximumFractionDigits:2});
          // Toplamı PDF'e ekle
          doc.text(`Toplam: ${total}`, 10, yPos + 10);

          // Sayfa numarasını ekle
          const pageCountStr = `Sayfa ${pageNumber} / ${totalPages}`;
          const pageHeight = doc.internal.pageSize.getHeight();
          doc.text(10, pageHeight - 10, pageCountStr);

          pageNumber++;

        });

        doc.save(reportTitle +'.pdf');
        console.log(doc)
      // }; //Logo açılacak
    } catch (e) {
      console.error('Report PDF Error!', e);
    }
  },

  // PushGrGridFilesToForm ten farkli olarak GecerlilikBaslangicTarihi ve GecerlilikBitisTarihi yok.
  PushGrGridLiteFilesToForm:(list,form)=>{
    let newList=[];
    for (var i = 0; i < list.length; i++) {
      var file = list[i];
      newList.push({Id:file.Id,DosyaTipi:file.DosyaTipi})
      if(file.Dosya===null) file.Dosya=new File([],"BosFileDikkateAlmayin");
      form.append('FormFiles', file.Dosya);

    }

    form.append('FilesStr', JSON.stringify(newList));

    return form;
  },

  PushGrGridFilesToForm:(list,form)=>{
    let newList=[];
    for (var i = 0; i < list.length; i++) {
      var file = list[i];
      newList.push({Id:file.Id,GecerlilikBaslangicTarihi:file.GecerlilikBaslangicTarihi,GecerlilikBitisTarihi:file.GecerlilikBitisTarihi,DosyaTipi:file.DosyaTipi})
      if(file.Dosya===null) file.Dosya=new File([],"BosFileDikkateAlmayin");
      form.append('FormFiles', file.Dosya);

    }

    form.append('FilesStr', JSON.stringify(newList));

    return form;
  },

  PushGrGridCiroFilesToForm:(list,form)=>{
    let newList=[];
    for (var i = 0; i < list.length; i++) {
      var file = list[i];
      newList.push({Id:file.Id, DosyaTipi:file.DosyaTipi, YukleyenKullanici:file.YukleyenKullanici})
      if(file.Dosya===null) file.Dosya=new File([],"BosFileDikkateAlmayin");
      form.append('FormFiles', file.Dosya);

    }

    form.append('FilesStr', JSON.stringify(newList));

    return form;
  },

  PushGrGridSigortaToForm:(list,form)=>{
    let newList=[];
    for (var i = 0; i < list.length; i++) {
      var file = list[i];
      newList.push({Id:file.Id,GecerlilikBaslangicTarihi:file.GecerlilikBaslangicTarihi,GecerlilikBitisTarihi:file.GecerlilikBitisTarihi,DosyaTipi:file.DosyaTipi,ParaBirimi:file.ParaBirimi,Tutar:file.Tutar})
      if(file.Dosya===null) file.Dosya=new File([],"BosFileDikkateAlmayin");
      form.append('FormSigortalar', file.Dosya);

    }

    form.append('SigortalarStr', JSON.stringify(newList));

    return form;
  },

  /**
   * @description İlgili sürece ait yüklenmesi zorunlu olan fakat yüklenmemiş dosyaları tespit eder.
   * @dosyaTipleriEnum kontrol edilecek dosya tipleri (örneğin: personelDosyalariEnum)
   * @zorunluAlanlar Tüm zorunlu alanların listesi
   * @yuklenmisBelgeler Data objesi içerisinde tutulan dosyaların listesi
   * @returns Array - parametrede verilen enumda yer alan objelerden oluşan bir array döndürür.
   **/
  eksikZorunluBelgeler: (yuklenmisBelgeler, zorunluAlanlar, dosyaTipleriEnum) => {
    try{
      let zorunluBelgeler = dosyaTipleriEnum.filter(file => zorunluAlanlar.includes(file.alias))
      let eksikBelgeler = zorunluBelgeler.filter(zorunluBelge => yuklenmisBelgeler.filter(yuklenmisBelge => yuklenmisBelge.DosyaTipi === zorunluBelge.deger).length === 0)
      return eksikBelgeler;
    }
    catch(e){
      console.error(e)
      // TODO: Error handling
    }

  },
  TarihFormat:(tarih,justDate)=>{
    if(tarih===null || tarih===undefined) return null;
  let date=new Date(tarih);
  if(justDate===true) return String(date.getDate()).padStart(2, '0')+"."+String(date.getMonth()+1).padStart(2, '0')+"."+date.getFullYear();
  else return String(date.getDate()).padStart(2, '0')+"."+String(date.getMonth()+1).padStart(2, '0')+"."+date.getFullYear()+" "+String(date.getUTCHours()).padStart(2, '0')+":"+String(date.getMinutes()).padStart(2, '0');
},
  FormatTime(date) {
    var tarih=new Date(date);
    // Saat ve dakika değerlerini al
  let hours = tarih.getUTCHours();
  let minutes = tarih.getUTCMinutes();

  // Saat ve dakika değerlerini iki basamaklı hale getir
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  // Sonuç olarak 'HH:mm' formatında döndür
  return hours + ':' + minutes;
},
   dataURLtoFile:(dataurl, filename)=> {
    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename+dataurl.split(';')[0].split('/')[1], {type:mime});
},

  getDataAndLabelsForChart: (chartBackendData, chartFrontendData = null,  i=0) => { // format for report
    try{
      let labels = [];
      let data = [];

      Object.entries(chartBackendData).forEach( ([label, value]) => {
        labels.push(label);
        data.push(value);
      })

      chartFrontendData['labels'] = labels;
      chartFrontendData['datasets'][i]['data'] = data;
    }
    catch(e){
      console.error("Error!");
    }
  },

  displayArea: (val, unit = 'meter') => {
    switch (unit){
      case 'meter':
        return val + ' m²';
      case 'feet':
        return val +' ft²';
      default:
        return val + ' m²';
    }
  },

  displayCurrency: (val, currency = 'TRY') => {
    if (val === "-") return val
    switch (currency){
      case 'USD':
        return val + ' $';
      case 'EUR':
        return val + ' €';
      case 'GBP':
        return val + ' £';
      case 'MKD':
        return val + ' ден';
      default:
        return val +' ₺';
    }
  },

  displayCurrencyUnit: (currency = 'TRY') => {
    switch (currency){
      case 'USD':
        return ' $';
      case 'EUR':
        return ' €';
      case 'GBP':
        return ' £';
      case 'MKD':
        return ' ден';
      default:
        return ' ₺';
    }
  },

  getMyUserId:()=>{
    const accessTokenObject = token_solver(localStorage.getItem(process.env.ACCESS_TOKEN));
    return  accessTokenObject["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
  },

  getEnumDisplayName:(enumList,enumValue)=>{
   return enumList.find(x=>x.value===enumValue).label
  },

  /**
   * @description Formlarda kullanılan tarih inputlarının geçerli aralıklarda seçildiğinin kontrolunu saglayabilmek amaclandi.Baslangic tarihi gecmis tarih secilemez!
   * @startDate Baslangic Tarihi, Date Objesi veya string olarak YYYY-MM-DD verilebilir. saat dogru ise optional field'a gerek yok
   * @endDate Bitis Tarihi, Date Objesi veya string olarak YYYY-MM-DD verilebilir. saat dogru ise optional field'a gerek yok
   * @optionalStartTime string hh:mm saat karsilastirmasi yapmak icin eger startDate yetersiz ise tercihen kullanilabilir, olasi hatalarin onune gecmek icin tavsiye edilir
   * @optionalEndTime string hh:mm saat karsilastirmasi yapmak icin eger endDate yetersiz ise tercihen kullanilabilir, olasi hatalarin onune gecmek icin tavsiye edilir
   * @enablePastTime boolean degeri true yapilirsa baslangic tarihi istenirse gecmis bir tarih olarak da secilebilir ancak default deger olarak false atandi.
   * @returns Bool - ilgili tarihler kullanima uygun ise true.
   **/
  startDateEndDateValidationControl: (startDate,endDate, optionalStartTime = null, optionalEndTime = null, enablePastTime = false) => {
    let DateNow = new Date()
    // console.log(typeof startDate)
    if (typeof startDate === "number"){
      startDate = new Date(startDate)
    }
    else if (typeof startDate === 'string'){
      DateNow =  DateNow.toJSON().slice(0, 10);
    }
    else if (typeof startDate === 'object'){
      // let DateNow be an object
    }
    /*console.log(typeof optionalStartTime)
    console.log(typeof optionalEndTime)
    console.log(typeof startDate)
    console.log(typeof endDate)

    console.log(optionalStartTime)
    console.log(optionalEndTime)
    console.log(startDate)
    console.log(endDate)*/
    if (optionalStartTime!==null && typeof optionalStartTime === 'object'){
      startDate.setHours(optionalStartTime.hours)
      startDate.setMinutes(optionalStartTime.minutes)
      optionalStartTime = optionalStartTime.hours.toString()+":"+optionalStartTime.minutes.toString()
    }
    // else if (optionalStartTime!==null && typeof optionalStartTime === 'string'){
    //   startDate.setHours(optionalStartTime.split("-")[0])
    //   startDate.setMinutes(optionalStartTime.split("-")[1])
    //   console.log(startDate)
    // }
    if (optionalEndTime!==null && typeof optionalEndTime === 'object'){
      endDate.setHours(optionalEndTime.hours)
      endDate.setMinutes(optionalEndTime.minutes)
      optionalEndTime = optionalEndTime.hours.toString()+":"+optionalEndTime.minutes.toString()
    }
    // else if (optionalEndTime!==null && typeof optionalEndTime === 'string'){
    //   endDate.setHours(optionalEndTime.split("-")[0])
    //   endDate.setMinutes(optionalEndTime.split("-")[1])
    //   console.log(endDate)
    // }

    if (DateNow>startDate && !enablePastTime){
      // should not be possible
      return false
    }
    else if (DateNow<=startDate || enablePastTime){

      if (startDate>endDate){
        // should not be possible
        return false
      }
      else if (startDate===endDate){
        //For same days
        if (optionalStartTime!==null && optionalEndTime !==null && optionalStartTime>=optionalEndTime){
          // faulty time selection
          return false
        }
        else if (optionalStartTime!==null && optionalEndTime !== null && optionalStartTime<optionalEndTime){
          // same day but OK
          return true
        }
        else return false
      }
      else if (endDate>startDate){
        return true
      }
    }
  },

  openPDFinNewTab:(URI)=>{
    let pdfWindow = window.open("")
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      encodeURI(URI) + "'></iframe>"
    )
  },

  openImageinNewTab:(URI)=>{
    let ImageWindow = window.open("")
    ImageWindow.document.write(
      "<iframe width='100%' height='100%' src='data:image/png;base64," +
      encodeURI(URI) + "'></iframe>"
    )
  },
  decompressToken:(token)=>{
    const gezipedData = atob(token)
    const gzipedDataArray = Uint8Array.from(gezipedData, c => c.charCodeAt(0))
    const ungzipedData = pako.ungzip(gzipedDataArray);
    return new TextDecoder().decode(ungzipedData);
  },
  getParaBirimleri:()=>{
    let accessTokenObject = token_solver(localStorage.getItem(process.env.ACCESS_TOKEN));
    let paraBirimleri = JSON.parse(accessTokenObject?.ParaBirimleri ?? "[]");
    return paraBirimleri;
  },

  // This function gives number of days in a given month, we used that in TahakkukOlusturPopup for Kira Tahakkuklari
  //
  // month & year parameters should be given in numbers
  getDaysInMonth:(month, year)=> {
  const daysInMonth = [31, 28 + $fns.isLeapYear(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return daysInMonth[month];
  },

  isLeapYear:(year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  },

  DisplayFormatWithDesiredDigits : (val, digitsAfterComa = 0, locales = 'tr-TR') => {
    if (isNaN(parseFloat(val))) return;
    else {
      let formattedValue;
      const parsedVal = parseFloat(val);
      // console.log(parsedVal)
      const fractionalPart = parsedVal % 1;
      // console.log(fractionalPart)
      if (fractionalPart === 0 && digitsAfterComa === 4) {
        formattedValue = parsedVal.toLocaleString(locales, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      } else {
        formattedValue = parsedVal.toLocaleString(locales, { minimumFractionDigits: digitsAfterComa, maximumFractionDigits: digitsAfterComa });
      }

      // Trim trailing zeros
      // formattedValue = formattedValue.replace(/0+$/, ''); //"0," olarak gorunmesine sebep oldugu icin kapatildi.

      return formattedValue;
    }
  },

  // Update text values with translations using $t()
  updateEnumTextsWithTranslations : (objectList,labelKey,t) => {//(tahsilEnum,"text",t)
    // const { t } = useI18n(); // t must be defined above in setup
    objectList.forEach((item, index) => {
      objectList[index][labelKey] = t(objectList[index][labelKey]);
    });
    return objectList;
  },

  localizer : (param,t) => {

    if (Array.isArray(param)) {
      param.forEach((val,idx) => {
        if(typeof val === 'object'){
          for(let v in val){
            if(typeof val[v] === 'string')
              val[v] = t(val[v]);
          }
        }
        else if(typeof val === 'string')
          param[idx] = t(val);
      });
    }
    else if (typeof param === 'object') {
      for(let p in param){
        if(typeof param[p] === 'string')
          param[p] = t(param[p]);
      }
    }
    else if (typeof param === 'string')
      param = t(param);

    return param;
  },

  // TUKETİM BIRIM FIYAT DUZENLEME POPUPINA GECICI COZUM OLARAK EKLENDI BASKA YERLERDE KULLANILMASI ONERILMEZ...
  containsOnlyNumbersDots : (inputString) => {
  // Regular expression to match only digits, commas, and decimal points
  const regex = /^[\d.]+$/;
  return regex.test(inputString);
},
  isValidNumber : (inputString) => {
    // Regular expression to match only digits and an optional decimal point with digits after it
    const regex = /^\d*\.?\d+$/;

    // Check if the input string matches the regular expression
    return regex.test(inputString) && // Check if the string contains only digits, optional decimal point, and digits after the decimal point
      inputString.indexOf('.') === inputString.lastIndexOf('.'); // Check if there is at most one decimal point

  }

}
