//v-gr-enter her sayfada en fazla 1 adet bulunabilir
export default {
  beforeMount (el) {
    if(!window.fnEnter1){
      window.addEventListener("keyup", window.fnEnter1 = function (e){
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          e.preventDefault();
          el.click();
        }
      });
    }
  },
  beforeUnmount(){
    window.removeEventListener("keyup", window.fnEnter1);
    window.fnEnter1 = undefined;
  }
}
