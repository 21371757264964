/**
 * GENERAL PURPOSE
 * ------------------
 * Return browser title, can set string or function
 * ------------------------
 * EXAMPLE
 * --------------
 * <script>export default {title: 'TİTLE'} </script>
 * OR
 * <script>export default {title(){return 'TİTLE'}} </script>
 * **/
import {useI18n} from "vue-i18n";

function getTitle(vm){
  const {title} = vm.$options
  if(title){
    return typeof title === 'function' ? title.call(vm) : title
  }
}

export default {
  created() {
    const title = getTitle(this)
    const { t } = useI18n();

    if (title) {
      console.log()
      document.title = `EPPSO | ${t(title)}`;
    }
  }
}
