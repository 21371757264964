// default src/boot/i18n.js content:

import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import gr_axios from "src/infrastructure/request_base/gr_axios";

// you'll need to create the src/i18n/index.js file too

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("language")|| 'tr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'tr',
  messages:loadLocaleMessagesLocalStorage()
})


function loadLocaleMessagesLocalStorage(){
  return JSON.parse(localStorage.getItem("Ceviri"));
}

function loadLocaleMessagesDb () {
  const getSozluk = gr_axios('GET', 'Sozluk/GetSozluk');
  getSozluk.getResponse();

  return getSozluk.response.value;
}

function loadLocaleMessagesJson () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default ({ app }) => {
  // Set i18n instance on app
  app.use(i18n)
}

export { i18n }
