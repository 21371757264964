import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import {$fns} from 'src/infrastructure/functions/functions'

export default function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  });

  Router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if ($fns.isLoggedIn() === false)
        next({name: 'login', query: to.query});
    }

    if(to.meta.hasOwnProperty('middleware'))
      to.meta.middleware(from, to, next);

    /*if(to.name === 'login' && from.name === 'mailOnay' && to.params['enrollment_id']){ //http://localhost:2424/login?enrollment_id=TD09ZK2Rh0SYky3OcNrbwg
      to.fullPath += '?enrollment_id=' + to.params['enrollment_id'];
    }

    if(to.name === 'login' && from.name === 'mailOnay' && to.params['tedarikci_personel_kayit_id']){ //http://localhost:2424/login?enrollment_id=TD09ZK2Rh0SYky3OcNrbwg
      to.fullPath += '?tedarikci_personel_kayit_id=' + to.params['tedarikci_personel_kayit_id'];
    }*/

    let onFailRedirectTo = 'NotFound';
    let UnauthorizedRedirectTo = 'Error403';

    if(to.meta.hasOwnProperty('onFailRedirectTo'))
      onFailRedirectTo = to.meta['onFailRedirectTo'];

    if (to.meta.hasOwnProperty('hasAtLeastOneOf')) {
      if($fns.hasAtLeastOneOf(to.meta['hasAtLeastOneOf']) === false) next({ name: UnauthorizedRedirectTo })
    }

    if (to.meta.hasOwnProperty('hasAllOf')) {
      if($fns.hasAllOf(to.meta['hasAllOf']) === false) next({ name: UnauthorizedRedirectTo })
    }

    if (to.meta.hasOwnProperty('hasNoneOf')) {
      if($fns.hasNoneOf(to.meta['hasNoneOf']) === false) next({ name: UnauthorizedRedirectTo })
    }

    next();
  });

  return Router
}
