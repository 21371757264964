import axios from 'axios';
import {reactive, toRefs} from 'vue';
import {paginateIt} from "src/infrastructure/functions/table_filter";
import {isEmpty} from 'lodash';
import Router from '../../router';
import {$fns} from "src/infrastructure/functions/functions";
import * as pako from "pako"
const qS = require('query-string');

/**
 * GENERAL PURPOSE
 * ---------------------
 * This is a wrapper for axios requests which is used to communicate with the backend application.
 * A certain type of HTTP request is made to a specified URL to do operations on the backend side.
 * Additional data can be sent alongside the request, but it is optional.
 *
 * PARAMETERS
 * ---------------------
 * @method: HTTP Request Type (GET, POST, etc.)
 * @urlWithoutRoot: The path of the method to be called at backend side, without the root ("Tanimlar/UlkeList")
 * @data: Data to be sent alongside the request [optional]
 **/
export function gr_axios(method,
                         urlWithoutRoot,
                         data = null,
                         contentType = 'application/json',
                         responseType = 'text',
                         dataParameterName = 'data') {

  // Define the URL that'll be used by the request
  const baseURL =  process.env.BACKEND_ROOT_URL;
  const url = urlWithoutRoot;

  // Define a reactive object that holds information about the request's state
  const state = reactive({
    response: [],
    error: {},
    isBusy: false,
    responseHeaders: {}
  });

  // Define some default headers for the request
  // Content type could be 'application/x-www-form-urlencoded' etc.

  var token=localStorage.getItem(process.env.ACCESS_TOKEN);
  if(token!==null){
    let gzipToken=pako.gzip(token,{ to: 'string' })
    token = btoa(String.fromCharCode.apply(null, gzipToken)); // gzip ile sıkıştırılmış veriyi Base64 formatına dönüştürüyoruz

  }
  axios.defaults.headers = {
    'Authorization': 'Bearer ' + token,
    'Content-Type': contentType
  }

  //catches error status
  function errorStatusHandler(errors){
    switch (errors.response.status) {
      case 500:
        state.error.data = 'Sunucu kaynaklı bir hata oluştu.';
        break;
      case 400:
        if (typeof errors.response.data === "string")
          state.error.data = errors.response.data;
        else
          state.error.data = errors.response.data.errors;
        break;
      case 403:
        state.error.data = 'Bu işlemi yapmak için yetkiniz bulunmamaktadır.';
        break;
      case 401:
        state.error.data = 'Bu işlemi yapmak için sisteme giriş yapmalısınız.';
        break;
      default:
        state.error.data = "Bilinmeyen bir hata oluştu. Sistem yöneticisine başvurun."
    }
  }

  // Send a request using axios
  const getResponse = async () => {
    // Attempt a request and save response if successful
    try
    {
      state.isBusy = true;
      const response = await axios({ method, baseURL, url, responseType, [dataParameterName]: data});
      state.response = response.data;
      state.responseHeaders = response.headers;
    }
      // If request fails for any reason, catch errors and update state with those errors
    catch (errors)
    {
      // - HTTP 401 Unauthorized -
      if(errors.response.status === 401) {

        axios({
          method: 'PUT', baseURL, url: 'Home',
          data: qS.stringify({
            refreshToken: localStorage.getItem(process.env.REFRESH_TOKEN)
          }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })

        .then( response => {
            localStorage.setItem(process.env.ACCESS_TOKEN, $fns.decompressToken(response.data["AccessToken"]));
            localStorage.setItem(process.env.REFRESH_TOKEN, response.data["RefreshToken"]);
            localStorage.setItem(process.env.USER_PHOTO, response.data["ProfilePhoto"]);

            axios({
               method, baseURL, url, [dataParameterName]: data,
               headers:{
                  'Authorization': 'Bearer ' + localStorage.getItem(process.env.ACCESS_TOKEN),
                  'Content-Type': contentType
                }
            })
            .then( secondResponse =>  { state.response = secondResponse.data; } )
            .catch( err => {
                errorStatusHandler(err);
                state.error.status = err.response.status;
              }
            );

        })
        .catch(async () => {
          localStorage.removeItem(process.env.ACCESS_TOKEN);
          localStorage.removeItem(process.env.REFRESH_TOKEN);
          await Router.push({ name: 'login'});
          state.error.data = 'Oturumunuzun süresi doldu, lütfen yeniden giriş yapın.';
          state.error.status = 401;
        });
      }
      else
      {
        errorStatusHandler(errors);
        state.error.status = errors.response.status;
      }
    }
    finally {
      state.isBusy = false;
    }
  };

  return {...toRefs(state), getResponse};
}

/**
 * GENERAL PURPOSE
 * ---------------------
 * This is a wrapper method to request data for gr-table and gr-serverless-table components.
 * Information like pagination values, data object and error object are passed on to the method.
 * Pagination and filters are applied depending on whether the component is server based or not.
 * These values are then stored in tableReqData array which is then passed onto the gr_axios
 * method to make an HTTP request to the backend application.
 * Either the error or the response is saved to the caller component's appropriate field.
 *
 * PARAMETERS
 * ---------------------
 * @pageData: An array that contains the current pagination and filter values (no filters are applied if is null)
 * @baseObject: The object that holds all the information regarding the displayed data values in the caller component (e.g. ulkeData)
 * @backendErrArrayObj: The object that helps display errors on screen if there are any (e.g. rData)
 * @method: The type of HTTP request to be made
 * @errArray:
 **/
async function tableRequester(pageData, baseObject, backendErrArrayObj, method = 'POST', errArray = 'backendErrors')
{
  baseObject.loading = true;
  let tableReqData = {};
  const isThisBackendPageRequest = !isEmpty(pageData);
  if(isThisBackendPageRequest){
    paginateIt(baseObject, pageData.pagination, pageData.filter, tableReqData);
  }else{
    tableReqData.Filter = "";
  }

  try{
    /*if(typeof pageData.filter === 'string' || pageData.filter instanceof String)
      tableReqData.Filter = pageData.filter;*/
    if(isEmpty(pageData.filter.textFilters)===false)  tableReqData.Filter =  pageData.filter.textFilters
    tableReqData.MonoEnumFilter = pageData.filter.monoEnumFilter;
    tableReqData.MultiEnumFilter = pageData.filter.multiEnumFilters;
    tableReqData.MonoStringEnumFilter = pageData.filter.monoStringEnumFilter;
    tableReqData.MultiStringEnumFilter = pageData.filter.multiStringEnumFilters;
  }
  catch(e){
    console.warn("Filtreleme hatası!");
  }

  backendErrArrayObj[errArray] = new Set();
  const{response, error, isBusy, getResponse} = gr_axios(method, baseObject.dataUrl, tableReqData);
  baseObject.loading = isBusy;
  await getResponse();

  // If the response does not contain an error, save response
  // If there was an error push the error to caller table's rData field
  if(isEmpty(error.value))
  {
    // Save data recieved from backend to the table component's tdata field that called this method
    baseObject.tdata = response.value["TableData"];
    // Data count is the total number of data returned from query with current filters
    if(isThisBackendPageRequest)
    { baseObject.pagination.rowsNumber = response.value["DataCount"]; }
  }
  else{
    //vue2 referansa ekleyince değiştiğini anlamıyor.
    //vue3 versiyonunda umarım düzelir.
    //düzelmezse clone üzerinden gitmeli.
    const cloneSet = new Set(backendErrArrayObj[errArray]);
    cloneSet.add(error.value["data"]);
    backendErrArrayObj[errArray] = cloneSet;
  }

  baseObject.loading = false;
}



async function dropdownDataRequester(requestObject){
  const fopObject = { pageSize: requestObject.pageSize, pageNumber: requestObject.pageNumber, filter: requestObject.filter};
  const{response, error, isBusy, getResponse} = gr_axios('POST', requestObject['requestUrl'], fopObject);
  await getResponse();

  if(isEmpty(error.value))
  {
    //console.log(response.value["TableData"]);
    requestObject['data'] = response.value["TableData"];
    requestObject['totalCount'] = response.value["DataCount"];
    //console.log(requestObject);
  }

}

//********************************************************************************************************************//
/**
 * GENERAL PURPOSE
 * ---------------------
 * Unifies backend and frontend errors
 *
 * PARAMETERS
 * ---------------------
 * @firstObject: errorTexts field of relevant field
 * @secondObject: rData.badRequests which is populated when when a request returns a bad request
 * @searchKey: name of the field that produced the error
 * @toBeUnified: tells the function if there is something to unify (this field is determined before the unifier is called when rData.relevantBadRequestsField is compared with the name of the badrequest error field
 **/
function errorUnifier(firstObject, secondObject, searchKey, toBeUnified = true, jsonConvert = false){
  // TODO - fieldErrors oluştururken kısa haliyle yazmayı dene (jsonConvert = true vermelisin)
  if(jsonConvert){
    firstObject = JSON.parse(JSON.stringify(firstObject));
  }

  if(!secondObject.hasOwnProperty(searchKey) || !toBeUnified){
    return firstObject;
  }

  let objectToMerge = {};
  secondObject[searchKey].forEach((val) => {
    objectToMerge[val] = val;
  });

  return {...firstObject, ...objectToMerge};
}

//********************************************************************************************************************//

export default gr_axios
export{
  gr_axios as axiosBase,
  tableRequester,
  errorUnifier,
  dropdownDataRequester
}
